import { type FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AddUser, Close, Copy, Envelope, Gift, Send } from '@/assets/icons';
import { Button, Input } from '@/components/global';
import { useAppSelector, useAppTranslation } from '@/hooks';
import { appToast } from '@/lib/ToastContainers';
import { SaasService } from '@/services';

interface ReferralModalProps {
  isIsOpenModal: boolean;
  onClose: () => void;
}

const ReferralModal: FC<ReferralModalProps> = ({ isIsOpenModal, onClose }) => {
  const { t } = useAppTranslation();
  const code = useAppSelector((state) => state.global.company?.referralCode);
  const {
    handleSubmit,
    register,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().required(),
      })
    ),
  });

  const [copied, setCopied] = useState(false);

  const handleSend = (data: { email: string }) => {
    SaasService.sendReferralEmail(data.email)
      .then(() => {
        appToast.success(t('An email has been sent to the entered address.'));
      })
      .catch(() => {
        appToast.error(t('Something went wrong'));
      });
  };

  const handleCopy = async () => {
    await navigator.clipboard.writeText('https://app.uvey.ai?code=' + code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  const renderTransactionStep = () => {
    return (
      <div className="flex flex-col gap-8 p-4">
        {/* HEADER */}
        <div className="flex justify-between items-start">
          <div className="flex flex-col gap-2">
            <h2 className="font-semibold text-xl text-grayscale-text-title">
              {t('REFERRAL')}
            </h2>
            <p className="text-sm text-grayscale-text-subtitle font-normal">
              {t('INVITE_YOUR_CUSTOMERS_IN_3_EASY_STEPS')}
            </p>
          </div>
          <button onClick={onClose}>
            <Close width={16} height={16} strokeColor="#000" />
          </button>
        </div>
        {/* HOW IT WORKS */}
        <div className="flex justify-between gap-4 items-start">
          <div className="flex flex-col justify-center items-center gap-4 w-[172px]">
            <span className="bg-primary-subtle rounded-full p-6">
              <Envelope width={24} height={24} strokeColor="#0756F2" />
            </span>
            <span className="flex flex-col gap-2">
              <p className="font-semibold text-center">{t('INVITATION')}</p>
              <p className="text-xs text-center">
                {t('SEND_YOUR_UNIQUE_REFERRAL')}
              </p>
            </span>
          </div>
          <div className="flex flex-col justify-center items-center gap-4 w-[172px]">
            <span className="bg-primary-subtle rounded-full p-6">
              <AddUser width={24} height={24} strokeColor="#0756F2" />
            </span>
            <span className="flex flex-col gap-2">
              <p className="font-semibold text-center">{t('REGISTRATION')}</p>
              <p className="text-xs text-center">{t('YOUR_FRIENDS_SIGN_UP')}</p>
            </span>
          </div>
          <div className="flex flex-col justify-center items-center gap-4 w-[172px]">
            <span className="bg-primary-subtle rounded-full p-6">
              <Gift width={24} height={24} strokeColor="#0756F2" />
            </span>
            <span className="flex flex-col gap-2">
              <p className="font-semibold text-center">{t('MONTH_FREE')}</p>
              <p className="text-xs text-center">
                {t('FROM_THEIR_FIRST_ACTION')}
              </p>
            </span>
          </div>
        </div>
        <hr />
        {/* ACTIONS */}
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1">
              <h2 className="font-semibold text-sm text-grayscale-text-title">
                {t('ADD_YOUR_FRIENDS_EMAIL_ADDRESS')}
              </h2>
              <p className="text-xs text-grayscale-text-subtitle font-normal">
                {t('ENTER_THE_EMAIL_ADDRESS_TO_SEND_A_DIRECT_INVITATION')}
              </p>
            </div>
            <form onSubmit={handleSubmit(handleSend)}>
              <div className="flex items-center gap-4">
                <Input
                  placeholder="email@gmail.com"
                  type="email"
                  refs={register('email')}
                />
                <Button
                  size="small"
                  label={t('SEND')}
                  sensitivity="Secondary"
                  icon={<Send width={16} height={16} strokeColor="#0756F2" />}
                  iconPosition="right"
                  disabled={!isValid}
                />
              </div>
            </form>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-3">
              <h2 className="font-semibold text-sm text-grayscale-text-title">
                {t('OR_COPY_YOUR_REFERRAL_LINK')}
              </h2>
              <p className="text-xs text-grayscale-text-subtitle font-normal">
                {t('SHARE_YOUR_UNIQUE_LINK_WITH_A_SINGLE_CLICK')}
              </p>
            </div>
            <div className="flex items-center gap-4">
              <Input value={'https://app.uvey.ai?code=' + code} />
              <Button
                size="small"
                label={copied ? t('COPIED') : t('COPY')}
                icon={<Copy width={16} height={16} strokeColor="#fff" />}
                iconPosition="right"
                onClick={handleCopy}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Dialog fullWidth onClose={onClose} open={isIsOpenModal}>
      <div className="rounded-lg">{renderTransactionStep()}</div>
    </Dialog>
  );
};

export default ReferralModal;
