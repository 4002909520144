import { billingAPI } from './apis';
import type { BillMailData, Client, Document, Product } from './models';

export default class BillingService {
  static async getAllBills(filters: any, signal?: AbortSignal) {
    const queryParameters: string[] = [];

    if (filters.fiscalYear) {
      queryParameters.push(`fiscalYear=${filters.fiscalYear}`);
    }

    if (filters.searchQuery) {
      queryParameters.push(`searchQuery=${filters.searchQuery}`);
    }

    if (filters.page) {
      queryParameters.push(`page=${filters.page}`);
    }

    if (filters.pageSize) {
      queryParameters.push(`pageSize=${filters.pageSize}`);
    }

    if (filters.status) {
      queryParameters.push(`status=${filters.status}`);
    }

    if (filters.startDate) {
      queryParameters.push(`startDate=${filters.startDate}`);
    }

    if (filters.endDate) {
      queryParameters.push(`endDate=${filters.endDate}`);
    }

    if (filters.amountAsc) {
      queryParameters.push(`amountAsc=${filters.amountAsc}`);
    }

    if (filters.amountDesc) {
      queryParameters.push(`amountDesc=${filters.amountDesc}`);
    }

    if (filters.dateAsc) {
      queryParameters.push(`dateAsc=${filters.dateAsc}`);
    }

    if (filters.dateDesc) {
      queryParameters.push(`dateDesc=${filters.dateDesc}`);
    }

    if (filters.numberAsc) {
      queryParameters.push(`numberAsc=${filters.numberAsc}`);
    }
    if (filters.numberDesc) {
      queryParameters.push(`numberDesc=${filters.numberDesc}`);
    }

    return billingAPI.get(`/v1/invoices?${queryParameters.join('&')}`, {
      signal,
    });
  }
  static async getAllQuotes(filters: any, signal?: AbortSignal) {
    const queryParameters: string[] = [];

    if (filters.fiscalYear) {
      queryParameters.push(`fiscalYear=${filters.fiscalYear}`);
    }

    if (filters.page) {
      queryParameters.push(`page=${filters.page}`);
    }

    if (filters.pageSize) {
      queryParameters.push(`pageSize=${filters.pageSize}`);
    }

    if (filters.status) {
      queryParameters.push(`status=${filters.status}`);
    }

    return billingAPI.get(`/v1/quotes?${queryParameters.join('&')}`, {
      signal,
    });
  }
  static async getAllDeliveryNotes(filters: any, signal?: AbortSignal) {
    const queryParameters: string[] = [];

    if (filters.fiscalYear) {
      queryParameters.push(`fiscalYear=${filters.fiscalYear}`);
    }

    if (filters.page) {
      queryParameters.push(`page=${filters.page}`);
    }

    if (filters.pageSize) {
      queryParameters.push(`pageSize=${filters.pageSize}`);
    }

    if (filters.status) {
      queryParameters.push(`status=${filters.status}`);
    }

    return billingAPI.get(`/v1/delivery-notes?${queryParameters.join('&')}`, {
      signal,
    });
  }
  static async createNewDocument(
    data: Document,
    documentType: string,
    signal?: AbortSignal
  ) {
    return billingAPI.post(`/v1/${documentType}s`, data, {
      signal,
    });
  }

  static async deleteBill(id: string, signal?: AbortSignal) {
    return billingAPI.delete(`/v1/invoices/${id}`, {
      signal,
    });
  }

  static async cancelBill(id: string, signal?: AbortSignal) {
    return billingAPI.patch(`/v1/invoices/${id}/cancel`, {
      signal,
    });
  }

  static async deleteQuote(id: string, signal?: AbortSignal) {
    return billingAPI.delete(`/v1/quotes/${id}`, {
      signal,
    });
  }

  static async deleteDeliveryNote(id: string, signal?: AbortSignal) {
    return billingAPI.delete(`/v1/delivery-notes/${id}`, {
      signal,
    });
  }

  static async updateDocument(
    id: string,
    data: Document,
    documentType: String,
    signal?: AbortSignal
  ) {
    return billingAPI.put(`/v1/${documentType}s/${id}`, data, {
      signal,
    });
  }

  static async getDocument(
    id: string,
    documentType: string,
    signal?: AbortSignal
  ) {
    return billingAPI.get(`/v1/${documentType}s/${id}`, {
      signal,
    });
  }

  static async archiveQuote(id: string, signal?: AbortSignal) {
    return billingAPI.patch(`/v1/quotes/${id}/archive`, {
      signal,
    });
  }
  static async archiveDeliveryNote(id: string, signal?: AbortSignal) {
    return billingAPI.patch(`/v1/delivery-notes/${id}/archive`, {
      signal,
    });
  }
  static async validateBill(
    id: string,
    bill: Document,
    sendMail: boolean = false,
    signal?: AbortSignal
  ) {
    let path = `/v1/invoices/${id}/validate`;
    if (sendMail) {
      path = path + '?send_mail=true';
    }
    return billingAPI.patch(path, bill, {
      signal,
    });
  }

  static async sendValidationEmail(
    id: string,
    bill: Document,
    signal?: AbortSignal
  ) {
    let path = `/v1/invoices/${id}/validation-email`;

    return billingAPI.post(path, bill, {
      signal,
    });
  }

  static async finalizeBill(id: string, signal?: AbortSignal) {
    return billingAPI.patch(`/v1/invoices/${id}/finalize`, {
      signal,
    });
  }

  static async createNewProduct(data: Product, signal?: AbortSignal) {
    return billingAPI.post(`/v1/products`, data, {
      signal,
    });
  }

  static async getAllProducts(signal?: AbortSignal, filters?: any) {
    const queryParameters: string[] = [];
    if (filters.pageNumber) {
      queryParameters.push(`pageNumber=${filters.pageNumber}`);
    }

    if (filters.pageSize) {
      queryParameters.push(`pageSize=${filters.pageSize}`);
    }

    if (filters.searchQuery) {
      queryParameters.push(`searchQuery=${filters.searchQuery}`);
    }

    return billingAPI.get(`/v1/products?${queryParameters.join('&')}`, {
      signal,
    });
  }

  static async getFullListProducts(signal?: AbortSignal, filters?: any) {
    const queryParameters: string[] = [];
    if (filters.searchQuery) {
      queryParameters.push(`searchQuery=${filters.searchQuery}`);
    }

    return billingAPI.get(
      `/v1/products/full-list?${queryParameters.join('&')}`,
      {
        signal,
      }
    );
  }

  static async deleteProduct(id: string, signal?: AbortSignal) {
    return billingAPI.delete(`/v1/products/${id}`, {
      signal,
    });
  }

  static async updateProduct(data: Product, id: string, signal?: AbortSignal) {
    return billingAPI.put(`/v1/products/${id}`, data, {
      signal,
    });
  }

  static async createNewClient(data: Client, signal?: AbortSignal) {
    return billingAPI.post(`/v1/clients`, data, {
      signal,
    });
  }

  static async getAllClients(signal?: AbortSignal) {
    return billingAPI.get(`/v1/clients`, {
      signal,
    });
  }

  static async getClientsList(filters?: any, signal?: AbortSignal) {
    const queryParameters: string[] = [];
    if (filters.pageNumber) {
      queryParameters.push(`pageNumber=${filters.pageNumber}`);
    }

    if (filters.pageSize) {
      queryParameters.push(`pageSize=${filters.pageSize}`);
    }

    if (filters.searchQuery) {
      queryParameters.push(`searchQuery=${filters.searchQuery}`);
    }

    if (filters.searchQuery) {
      queryParameters.push(`searchQuery=${filters.searchQuery}`);
    }

    if (filters.fiscalYear) {
      queryParameters.push(`fiscalYear=${filters.fiscalYear}`);
    }

    return billingAPI.get(
      `/v1/clients/client-dashboard?${queryParameters.join('&')}`,
      {
        signal,
      }
    );
  }

  static async deleteClient(categoryCode: string, signal?: AbortSignal) {
    return billingAPI.delete(`/v1/clients/${categoryCode}`, {
      signal,
    });
  }

  static async updateClient(data: Client, id: string, signal?: AbortSignal) {
    return billingAPI.put(`/v1/clients/${id}`, data, {
      signal,
    });
  }

  static async getClientByCategory(categoryCode: string, signal?: AbortSignal) {
    return billingAPI.get(`/v1/clients/${categoryCode}`, {
      signal,
    });
  }

  static async getFilePreview(
    data: Document,
    documentType: string,
    signal?: AbortSignal
  ) {
    return billingAPI.post(`/v1/${documentType}s/preview/pdf`, data, {
      signal,
      responseType: 'blob',
    });
  }
  static async generateDocRSE(
    data: Document,
    documentType: string,
    signal?: AbortSignal
  ) {
    return billingAPI.post(`/v1/${documentType}s/generate/pdf`, data, {
      signal,
      responseType: 'blob',
    });
  }

  static async getBillMails(
    data: { fiscalYear: number },
    signal?: AbortSignal
  ) {
    return billingAPI.get(`/v1/settings`, {
      signal,
    });
  }

  static async createBillMails(data: BillMailData, signal?: AbortSignal) {
    return billingAPI.post(`/v1/settings`, data, {
      signal,
    });
  }
}
