import { type FC } from 'react';

import { type IconProps } from './IconType';

const Lock: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.60005 8.80002V7.88574C6.60005 4.84683 9.00862 2.40002 12 2.40002C14.9915 2.40002 17.4001 4.84683 17.4001 7.88574V8.80002M6.60005 8.80002C5.61005 8.80002 4.80005 9.62288 4.80005 10.6286V19.7714C4.80005 20.7772 5.61005 21.6 6.60005 21.6H17.4001C18.3901 21.6 19.2001 20.7772 19.2001 19.7714V10.6286C19.2001 9.62288 18.3901 8.80002 17.4001 8.80002M6.60005 8.80002H17.4001M12 16.2V13.8"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        stroke-linecap="round"
      />
    </svg>
  );
};

export default Lock;
