import { type FC, useEffect, useState } from 'react';

import { Outlet } from 'react-router';

import { Navbar, Sidebar } from '@/components/layouts';

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 796) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };
    handleResize(); // Call on initial render
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="relative flex flex-1 flex-col bg-white min-w-0">
        {/* Navbar */}
        <Navbar />

        {/* Main section */}
        <main className="bg-white h-[calc(100vh-90px)] overflow-y-auto min-w-0 min-h-0">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
