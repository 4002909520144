import * as yup from 'yup';

export const BillFormSchema = yup.object().shape({
  clientId: yup.string().required(),
  date: yup.string().required(),
  headerInfo: yup.string(),
  footerInfo: yup.string(),
  paymentDeadline: yup.string(),
  globalDiscount: yup.number(),
  advancePayment: yup.number(),
  globalDiscountPercentage: yup.number(),
  requestor: yup.string(),
  note: yup.string(),
  invoiceItems: yup
    .array()
    .of(
      yup.object().shape({
        productId: yup.string().nullable(),
        order: yup.string().required(),
        totalHT: yup.number().required(),
        description: yup.string(),
        discountPercentage: yup.string(),
        productDesignation: yup.string(),
        discount: yup.string(),
        quantity: yup.string().required(),
        unit: yup.string().nullable(),
        productVatRate: yup.string(),
        productPriceUHT: yup.string(),
        invoiceId: yup.string(),
      })
    )
    .min(1),
});

export const ProductFormSchema = yup.object().shape({
  name: yup.string().required(),
  designation: yup.string().required(),
  priceUHT: yup.number().required(),
  vatRate: yup.number().required(),
  unit: yup.string().required(),
  category: yup.string().required(),
});

export const ClientFormSchema = yup.object().shape({
  name: yup.string().required(),
  taxId: yup.string(),
  categoryCode: yup.string().matches(/411/).min(4).required().max(8),
  address: yup.string(),
  type: yup.string().required(),
  email: yup.string().email(),
});

export const submitEmailSchema = yup.object().shape({
  emailReplyTo: yup.string().email().required(),
  emailCc: yup.string().required(),
});

export const KonnectForm = yup.object().shape({
  apiKey: yup.string().required(),
  addPaymentFeesToAmount: yup.boolean().required(),
});

export const FlouciForm = yup.object().shape({
  appToken: yup.string().required(),
  appSecret: yup.string().required(),
  developerTrackingId: yup.string().required(),
  acceptCard: yup.boolean().required(),
});

export type PaymentLinkForm = {
  method: string;
  apiKey: string;
  addPaymentFeesToAmount: boolean;
  appToken: string;
  appSecret: string;
  developerTrackingId: string;
  acceptCard: boolean;
};
