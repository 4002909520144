import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useForm } from 'react-hook-form';

import { Download, File, Plus } from '@/assets/icons';
import { Badge, Button, Checkbox, LoadingSpinner } from '@/components/global';
import { useAppTranslation } from '@/hooks';
import { type BookEntriesExport, OperationsService } from '@/services';

import { Tabs } from '../common';
import HorizontalTabs from './HorizontalTabs';

export interface ExportDataType {
  start_date: string;
  end_date: string;
  export_format?: string;
}

enum DownloadFileStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

const Settings = () => {
  const { t } = useAppTranslation();

  const [downloadFileStatus, setDownloadFileStatus] = useState(
    DownloadFileStatus.INITIAL
  );
  const [dateRange, setDateRange] = useState(null as unknown as ExportDataType);
  const { data: exportHistory, refetch: refetchExportHistory } = useQuery<
    BookEntriesExport[]
  >(
    ['exportHistory'],
    async () => {
      const data = await OperationsService.getBookEntriesExportHistory();
      return data.sort(
        (a, b) =>
          new Date(b.exportedAt).getTime() - new Date(a.exportedAt).getTime()
      );
    },
    { initialData: [] }
  );

  const {
    handleSubmit: handleSubmitDate,
    register: registerDate,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      start_date: '',
      end_date: '',
      export_format: 'xls',
    },
  });

  const handleDownloadFile = () => {
    setDownloadFileStatus(DownloadFileStatus.IN_PROGRESS);
    OperationsService.exportData({
      ...dateRange,
      export_format: getValues('export_format'),
    })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `file.${getValues('export_format')}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        setDownloadFileStatus(DownloadFileStatus.SUCCESS);
        refetchExportHistory();
      })
      .catch(() => {
        setDownloadFileStatus(DownloadFileStatus.ERROR);
      });
  };

  const handleRangeDate = (data: ExportDataType) => {
    const newDateRange: ExportDataType = {
      start_date: moment(new Date(data.start_date)).format('YYYY-MM-DD'),
      end_date: moment(new Date(data.end_date)).format('YYYY-MM-DD'),
    };

    if (data.start_date || data.end_date) {
      setDateRange(newDateRange);
    }

    handleDownloadFile();
  };

  const downloadFile = (data: BookEntriesExport) => {
    setDownloadFileStatus(DownloadFileStatus.IN_PROGRESS);
    OperationsService.downloadPreviousBookEntriesExport(data.id)
      .then((res: any) => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `file.${data.exportFormat.toLowerCase()}`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
        setDownloadFileStatus(DownloadFileStatus.SUCCESS);
      })
      .catch(() => {
        setDownloadFileStatus(DownloadFileStatus.ERROR);
      });
  };

  return (
    <section className="p-6 flex flex-col gap-4 border-1 border-solid rounded-md bg-red w-full overflow-y-scroll bg-white">
      <div className="w-full mb-8">
        <Tabs selected="Settings" />
      </div>

      <h2 className="text-xl font-semibold mb-6">{t('Settings')}</h2>

      {/* Settings content goes here */}
      <div className="grid grid-cols-12 gap-4 ">
        <div className="col-span-3 h-[500px]">
          <HorizontalTabs selected="Export" />
        </div>
        <div className="col-span-9 rounded-xl border border-[#C8CFD5] p-4 h-[500px] overflow-y-scroll">
          <h3 className="text-xl font-semibold mb-2">Export</h3>
          <div className="border border-[#C8CFD5] rounded-lg p-3 mb-6">
            <div className="w-full">
              <h4 className="text-base font-semibold mb-4">
                Fichier d'exportation
              </h4>
              <div className="flex justify-between items-end gap-8">
                <form
                  onSubmit={handleSubmitDate(handleRangeDate)}
                  id={'date-picker'}
                  className="flex gap-2 w-full items-end"
                >
                  <div className="w-full">
                    <label
                      htmlFor="from"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      From
                    </label>
                    <input
                      type="date"
                      {...registerDate('start_date')}
                      id="from"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full h-12 p-2.5"
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="to"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      To
                    </label>
                    <input
                      type="date"
                      {...registerDate('end_date')}
                      id="to"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full h-12 p-2.5"
                    />
                  </div>

                  <div className="w-full">
                    <label
                      htmlFor="file-format"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      File Format
                    </label>

                    <div className="flex gap-3 items-center h-[48px] ">
                      <Checkbox
                        label={'XLS'}
                        type="radio"
                        name="subLocalTaxes"
                        value={'xls'}
                        refs={registerDate('export_format')}
                        onChange={(e) => {
                          setValue('export_format', e.target.value);
                        }}
                      />

                      <Checkbox
                        label={'CSV'}
                        type="radio"
                        name="subLocalTaxes"
                        value={'csv'}
                        refs={registerDate('export_format')}
                        onChange={(e) => {
                          setValue('export_format', e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <Button
                    type="submit"
                    label="Exporter"
                    size="medium"
                    icon={<Plus strokeColor="#fff" width={20} height={20} />}
                  />
                </form>
              </div>
            </div>
          </div>
          <h4 className="text-base font-semibold mb-4">
            {t('LIST_OF_REQUESTED_FILES')}
          </h4>
          <div className="accountant w-full">
            {exportHistory.length ? (
              <table className="table-auto w-full">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>{t('REQUESTED_AT')}</th>
                    <th>{t('FORMAT')}</th>
                    <th>{t('Status')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {exportHistory.reverse().map((el) => (
                    <tr key={el.id}>
                      <td>{getDateLabel(el.startDate, el.endDate)}</td>
                      <td>{moment(el.exportedAt).format('DD/MM/YYYY')}</td>
                      <td>{el.exportFormat}</td>
                      <td>
                        {downloadFileStatus ===
                          DownloadFileStatus.IN_PROGRESS && (
                          <div className="bg-[#f6f0e7] p-1 rounded-lg text-center flex justify-center">
                            <LoadingSpinner
                              width="20"
                              strokeWidth="2"
                              strokeColor="#cd8117"
                            />
                          </div>
                        )}
                        {(downloadFileStatus === DownloadFileStatus.INITIAL ||
                          downloadFileStatus ===
                            DownloadFileStatus.SUCCESS) && (
                          <Badge label={t('READY')} sensitivity="positive" />
                        )}
                      </td>
                      <td>
                        <div className="flex justify-end">
                          <Button
                            label={t('DOWNLOAD_FILE')}
                            size="small"
                            sensitivity="Secondary"
                            onClick={() => {
                              downloadFile(el);
                            }}
                            icon={
                              <Download
                                strokeColor="#0756F2"
                                width={16}
                                height={16}
                              />
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="w-full p-4 flex flex-col gap-2 justify-center items-center">
                <File />

                <p className="text-[#303840] font-semibold">
                  {t('NO_EXPORTED_FILES')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const getDateLabel = (startDate: Date | null, endDate: Date | null) => {
  if (!startDate && !endDate) {
    return 'All time';
  }

  if (!startDate) {
    return `Until ${moment(endDate).format('DD/MM/YYYY')}`;
  }

  if (!endDate) {
    return `Since ${moment(startDate).format('DD/MM/YYYY')}`;
  }

  return `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format(
    'DD/MM/YYYY'
  )}`;
};

export default Settings;
