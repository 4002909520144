import * as XLSX from 'xlsx';

interface Category {
  id: string;
  code: string;
  label: string;
  parentId: string;
  displayable: boolean;
  friendlyLabel: string;
  standard: boolean;
}

export interface CategoryData {
  [key: string]: Category;
}

/**
 * Join CSS classes
 * @param {any[]} ...classNames list of classes passed as arguments
 * @return {string} a string that contains the classes separated by space
 * @example cls('cls1', false && 'cls2', true && 'cls3') => 'cls1 cls3'
 */
export const cls = (...classNames: any[]): string =>
  !classNames || !Array.isArray(classNames)
    ? ''
    : classNames.filter((el) => !!el && typeof el == 'string').join(' ');

/**
 * Get an array of duplicate values
 * @param {any} value the value to duplicate
 * @param {number} length the length of the array
 * @return {any[]} an array that contains the duplicate values
 * @example getArrayOfDuplicateValues('Test', 3) => ['Test', 'Test', 'Test']
 */
export const getArrayOfDuplicateValues = (value: any, length: number): any[] =>
  Array.from({ length }, () => value);

/**
 *
 * @param {string} rib the rib to convert to **1234
 * @returns {string} converted rib
 */
export const convertRib = (rib: string) => `***${rib.substr(rib.length - 4)}`;

/**
 *
 * @param {number} amount the amount to formate
 * @param {string} Currency the amount currency
 * @example formatBalance(14050.411, "TND")
 * @returns 14.050,411 TND
 */
export const formatBalance = (amount?: number | string, currency?: string) => {
  const formattedAmount = (amount as unknown as number) * 1;

  if (isNaN(formattedAmount)) {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: currency ?? 'TND',
      //? In order to display all digits the line below should stay commented
      // maximumSignificantDigits: 3,
    }).format(0);
  } else {
    return (
      new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: currency ?? 'TND',
        //? In order to display all digits the line below should stay commented
        // maximumSignificantDigits: 3,
      }).format(formattedAmount) || 'Amount not valid'
    );
  }
};

export const formatAccountingPlan = (categories: Category[]) => {
  let formattedData: CategoryData = {};

  categories.forEach((el) => {
    formattedData[el.code as keyof typeof formattedData] = el;
  });

  return formattedData;
};

export const getCategoryName = (code: string, friendly: boolean = false) => {
  const state = localStorage.getItem('accountingPlan')
    ? JSON.parse(localStorage.getItem('accountingPlan') ?? '')
    : '';
  if (friendly) {
    return state?.[code]?.friendlyLabel ?? code;
  }

  return state?.[code]?.label ?? code;
};

/**
 * Converts an ISO 8601 date string to a short date format (YYYY-MM-DD).
 * @param isoDateString - The ISO 8601 date string to be converted.
 * @returns The short date string (YYYY-MM-DD).
 */
export const convertToShortDate = (isoDateString: string): string => {
  // Create a Date object from the input ISO date string.
  const date = new Date(isoDateString);

  // Use the toISOString method to obtain an ISO 8601 string and split it to extract the date part.
  const shortDate = date.toISOString().split('T')[0];

  return shortDate;
};

/**
 * Determines if a fiscal year is set.
 * @param fiscalYear The fiscal year to check.
 * @returns True if the fiscal year is set.
 */
export const isFiscalYearSet = (fiscalYear: number) => fiscalYear !== 0;

export const exportToCsv = (
  items: any[],
  headerKeys: string[],
  header: string[]
) => {
  const replacer = (_: any, value: any) => (value === null ? '' : value); // specify how you want to handle null values here
  const csv = [
    header.join(','), // header row first
    ...items.map((row) =>
      headerKeys
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    ),
  ].join('\r\n');

  return csv;
};

export const CsvToExcelConverter = (csvContent: string) => {
  // Parse CSV content
  const rows = csvContent
    .split('\n')
    .map((row) =>
      row.split(',').map((cell) => (cell[0] === '"' ? cell.slice(1, -1) : cell))
    );

  // Convert to Excel workbook
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(rows);
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // Convert workbook to binary Excel file
  const excelBuffer = XLSX.write(wb, { bookType: 'xls', type: 'buffer' });

  return excelBuffer;
};

/**
 *
 * @param bytes file size
 * @param decimals numbers after the dot
 * @returns formatted file size
 * @example  formatBytes(1024); return 1 KB
 */
export const formatBytes = (bytes: number, decimals?: number) => {
  if (bytes == 0) return '--';

  let ko = 1024,
    dm = decimals ?? 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(ko));

  return parseFloat((bytes / Math.pow(ko, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const isNumber = (num: any) => {
  return typeof num === 'number';
};
