export enum StatusEnum {
  ALL = 'ALL',
  NOT_ANALYZED = 'NOT_ANALYZED',
  ANALYZED = 'ANALYZED',
  ANALYSIS_SUCCESS = 'ANALYSIS_SUCCESS',
  NOT_ASSOCIATED = 'NOT_ASSOCIATED',
  PARTIALLY_ASSOCIATED = 'PARTIALLY_ASSOCIATED',
  ANALYSIS_IN_PROGRESS = 'ANALYSIS_IN_PROGRESS',
  ASSOCIATED = 'ASSOCIATED',
  VALIDATED_BY_OWNER = 'VALIDATED_BY_OWNER',
  VALIDATED_BY_ACCOUNTANT = 'VALIDATED_BY_ACCOUNTANT',
  UPLOADED = 'UPLOADED',
  PENDING = 'PENDING',
}

export enum ReceiptFilterStatus {
  INITIAL,
  IN_PROGRESS,
  DONE,
  ERROR,
}

export enum PaymentMethod {
  BANK_CARD = 'BANK_CARD',
  BANK = 'BANK',
  CCA = 'CCA',
  CASH = 'CASH',
  PP = 'PP',
}

export enum InvoiceType {
  ALL = 'ALL',
  SALE = 'SALE',
  PURCHASE = 'PURCHASE',
}
