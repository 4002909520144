import React, { type FC } from 'react';

import { type IconProps } from './IconType';

const Envelope: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.9999 10.4H2.3999V20C2.3999 20.8837 3.11625 21.6 3.9999 21.6H19.9999C20.8836 21.6 21.5999 20.8837 21.5999 20V10.4H19.9999M3.9999 10.4L11.9999 15.2L19.9999 10.4M3.9999 10.4V5.60002C3.9999 3.83271 5.43259 2.40002 7.1999 2.40002H16.7999C18.5672 2.40002 19.9999 3.83271 19.9999 5.60002V10.4M8.5 6H15.5M8.5 9.5H15.5"
        stroke={strokeColor}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Envelope;
