import { Link, useLocation } from 'react-router-dom';

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from '@/components/ui/BreadCrumb';

const BreadcrumbComponent = () => {
  const location = useLocation();
  const pathnames = location.pathname
    .split('/')
    .filter((x) => x)
    .slice(0, 4);

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbList>
          {pathnames.map((path, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;

            return (
              <>
                {!isContainsNumber(path) && (
                  <BreadcrumbItem key={routeTo} className="flex items-center">
                    {isLast ? (
                      <span className="text-grayscale-text-subtitle">
                        {formatBreadcrumb(path)}
                      </span>
                    ) : (
                      <Link
                        to={routeTo}
                        className="hover:text-grayscale-text-body hover:underline"
                      >
                        {formatBreadcrumb(path)}
                      </Link>
                    )}
                  </BreadcrumbItem>
                )}
                {isContainsNumber(path) ? null : isLast ? null : (
                  <BreadcrumbSeparator />
                )}
              </>
            );
          })}
        </BreadcrumbList>
      </Breadcrumb>
    </div>
  );
};

const formatBreadcrumb = (str: string) => {
  return str.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};

const isContainsNumber = (str: string) => {
  return /\d/.test(str);
};

export default BreadcrumbComponent;
