import React, { type FC } from 'react';

import { type IconProps } from './IconType';

const Copy: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.19999 8.80002H4.79999C3.47451 8.80002 2.39999 9.87454 2.39999 11.2C2.39999 12.5255 2.39999 17.8745 2.39999 19.2C2.39999 20.5255 3.47451 21.6 4.79999 21.6C4.79999 21.6 11.4745 21.6 12.8 21.6C14.1255 21.6 15.2 20.5255 15.2 19.2V16.2M19.2 2.40003L11.2 2.40002C9.87451 2.40002 8.79999 3.47454 8.79999 4.80002C8.79999 6.12551 8.79999 11.4745 8.79999 12.8C8.79999 14.1255 9.87451 15.2 11.2 15.2C11.2 15.2 17.8745 15.2 19.2 15.2C20.5255 15.2 21.6 14.1255 21.6 12.8V4.80003C21.6 3.47454 20.5255 2.40003 19.2 2.40003Z"
        stroke={strokeColor}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Copy;
