import type { FC, MouseEventHandler, ReactNode } from 'react';

import { Tooltip } from '@mui/material';

import type { IconPosition } from '../types';

interface SeparatorProps {
  /**
   * @default "Your text here"
   */
  label?: string;

  /**
   * @default ""
   */
  buttonLabel?: string;

  /**
   * @default null
   */
  icon?: ReactNode;

  /**
   * @default 'left'
   */
  iconPosition?: IconPosition;

  /**
   * @default false
   */
  showButton?: boolean;

  /**
   * @default null
   */
  onClick?: MouseEventHandler;

  /**
   * @default false
   */
  disabled?: boolean;

  /**
   * @default ""
   */
  toolTipMsg?: string;

  /**
   * @default null
   */
  customButton?: ReactNode;
}

/**
 *
 * @param {string} label
 * @param {string} buttonLabel
 * @param {ReactNode} icon
 * @param {IconPosition} iconPosition
 * @param {boolean} showButton
 * @param {MouseEventHandler} onClick
 * @param {boolean} disabled
 * @param {string} toolTipMsg
 * @example <Separator label="Title text" showButton icon={<Add />} onClick={() => {'do something'}} iconPosition="right" toolTipMsg={"this button is disabled"} />
 */
const Separator: FC<SeparatorProps> = ({
  label = 'Your text here',
  icon = null,
  iconPosition = 'left',
  showButton = false,
  buttonLabel,
  onClick,
  disabled,
  toolTipMsg,
  customButton,
}) => {
  return (
    <div className="w-full bg-[#F4F4F4] border-y border-[#DCDCDC] px-4 flex justify-between items-center h-[32px]">
      <div>
        <p className="text-xs text-[#808080]">{label}</p>
      </div>

      <Tooltip placement="top" title={toolTipMsg}>
        <div>
          {showButton ? (
            customButton ? (
              <div>{customButton}</div>
            ) : (
              <button
                disabled={disabled}
                onClick={onClick ? onClick : () => {}}
                className="flex justify-center items-center h-[24px] w-[28px] rounded border border-[#D5DBE1] bg-[#EBEEF1] disabled:opacity-50"
              >
                <>
                  {iconPosition === 'left' && icon}
                  {typeof buttonLabel === 'string' && buttonLabel !== '' ? (
                    <span>{buttonLabel}</span>
                  ) : (
                    buttonLabel
                  )}
                  {iconPosition === 'right' && icon}
                </>
              </button>
            )
          ) : (
            <div></div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default Separator;
