const EN_TRANSLATIONS = {
  // ==============================
  // App - Auth - SignUp
  // ==============================
  Register: 'Register',
  'Create your account and manage your accounting like a pro':
    'Create your account and manage your accounting like a pro',
  'First Name': 'First Name',
  'Last Name': 'Last Name',
  Email: 'Email',
  Password: 'Password',
  'Confirm password': 'Confirm password',
  'I accept the terms and conditions and the privacy policy.':
    'I accept the terms and conditions and the privacy policy.',
  Validate: 'Validate',
  GENERATE_CERTIFICATE: 'Generate certificate ',
  'Sign in with Gmail': 'Sign in with Gmail',
  'Already have an account?': 'Already have an account?',
  'Sign In': 'Sign In',
  'Passwords do not match.': 'Passwords do not match.',
  'Please enter your first name.': 'Please enter your first name.',
  'Please enter your last name.': 'Please enter your last name.',
  'This email already exists.': 'This email already exists.',
  'You must choose one option': 'You must choose one option',
  Create: 'Create',
  Matching: 'Matching',
  'This feature not available in this plan':
    'This feature not available in this plan',
  'forget password text':
    'You will instantly receive an e-mail with a secure password reset link. Be sure to check your inbox.\n If you have any problems or need assistance, please do not hesitate to contact our support team',
  // ==============================

  // ==============================
  // App - Auth - ResetPassword
  // ==============================
  'Reset your password': 'Reset your password',
  'The link will be sent to your email address.':
    'The link will be sent to your email address.',
  // 'Email': 'Email',
  'Please enter a valid email address.': 'Please enter a valid email address.',
  'Submit Reset': 'Submit Reset',
  INVOICE_VALIDATION_EMAIL_SEND_SUCCESS: 'Email sent successfully',
  'An email has been sent to the entered address.':
    'An email has been sent to the entered address.',
  "Don't have an account yet?": "Don't have an account yet?",
  'Enter a new password for your account.':
    'Enter a new password for your account.',
  'Reset password link is invalid or has expired':
    '      The reset password link is invalid or has expired.\n' +
    'Just click on the button below to request a new reset email.',
  'The reset password link has expired.':
    'The reset password link has expired.',
  'The reset password link is already used.':
    'The reset password link is already used.',
  'The reset password link is invalid.': 'The reset password link is invalid.',
  // 'Register': "Register",
  // ==============================

  // ==============================
  // App - Auth - SignIn
  // ==============================
  // 'Sign In': 'Sign In',
  // Email: 'Email',
  YEAR_IS_LOCKED: 'The year is locked',

  //Password: 'Password',
  'Stay connected': 'Stay connected',
  'Forgot your password?': 'Forgot your password?',
  // 'Sign In': 'Sign In',
  // 'Sign in with Gmail': 'Sign in with Gmail',
  // "Don't have an account yet?": "Don't have an account yet?",
  // Validate: 'Validate',
  'Email or password is incorrect.': 'Email or password is incorrect.',
  'This email is locked.': 'This email is locked.',
  'Your email has not been verified yet.':
    'Your email has not been verified yet.',
  'Welcome back to login. As a returning customer, you have access to all your previously saved information.':
    'Welcome back to login. As a returning customer, you have access to all your previously saved information.',
  // 'Please enter a valid email address.': 'Please enter a valid email address.',
  'The password must be valid.':
    'The password must be at least 8 characters long, and include at least one uppercase letter, one lowercase letter, one digit, and one special character.',
  // ==============================

  // ==============================
  // App - Wizard
  // ==============================
  'Company Information': 'Company Information',
  'Bank Accounts': 'Bank Accounts',
  Subscription: 'Subscription',
  'Bank account deleted successfully': 'Bank account deleted successfully',
  'Thank you for your payment!': 'Thank you for your payment!',
  'Go to dashboard': 'Go to dashboard',
  'We sent you an email': 'We sent you an email',
  COMPANY_ADDRESS_ERROR_MESSAGE:
    "Please enter your company's full address without using Arabic letters.",
  // ==============================

  // ==============================
  // App - Wizard - CompanyInfo
  // ==============================
  'Welcome to Bore!': 'Welcome to Uvey!',
  "Sign up now, it's free and only takes two minutes to manage your accounts.":
    "Register now and revolutionize your accounting! Your time is our priority. Free yourself up for what's important.",
  'Company Name': 'Company Name',
  'Please enter your company name.': 'Please enter your company name.',
  'Searching for the company...': 'Searching for the company...',
  'Type your company name.': 'Type your company name.',
  'ex. abyCompany': 'ex. abyCompany',
  'Company Form': 'Company Form',
  'Please select your company form.': 'Please select your company form.',
  'ex. SARL': 'ex. SARL',
  'Company Address': 'Company Address',
  'ex. North Urban Center': 'ex. North Urban Center',
  'Please enter your company address.': 'Please enter your company address.',
  'ex. Tunis': 'ex. Tunis',
  'Company Logo': 'Company Logo',
  'Click to upload': 'Click to upload',
  'or drag & drop': 'or drag & drop',
  'SVG, PNG, JPG or GIF (max. 800x400px)':
    'SVG, PNG, JPG or GIF (max. 800x400px)',
  'PNG, JPG, PDF (max. 1 MB)': 'PNG, JPG, PDF (max. 1 MB)',
  Next: 'Next',
  Previous: 'Previous',
  'Single-Person Limited Liability Company (SUARL)':
    'Single-Person Limited Liability Company (SUARL)',
  'Limited Liability Company (SARL)': 'Limited Liability Company (SARL)',
  'Public limited company (SA)': 'Public limited company (SA)',
  'Tax Number': 'Tax Number',
  'Please enter your company Tax Number.':
    'Please enter your company Tax Number.',
  Company: 'Company',
  'Add a new company': 'Add a new company',
  'First Name *': 'First Name *',
  'Last Name *': 'Last Name *',
  Country: 'Country',
  'Postal Code': 'Postal Code',
  Phone: 'Phone',

  // ==============================

  // ==============================
  // App - Wizard - BankAccounts
  // ==============================
  // 'Welcome to Bore!': 'Welcome to Bore!',
  // "Sign up now, it's free and only takes two minutes to manage your accounts.":
  //   "Sign up now, it's free and only takes two minutes to manage your accounts.",
  'Add a bank account': 'Add a bank account',
  UPDATE_BANK_ACCOUNT: 'Update a bank account',
  BANK_UPDATE_SUCCESS: 'Bank account updated successfully',
  ACCOUNT_CLOSED: 'Account closed',
  'Please type your RIB.': 'Please type your RIB.',
  'The RIB must contain 20 digits.': 'The RIB must contain 20 digits.',
  'This bank account is already added to your list.':
    'This bank account is already added to your list.',
  MAX_NB_BANK_ACCOUNTS_REACHED: 'You can have a maximum of ten bank accounts.',
  'Please verify your RIB.': 'Please verify your RIB.',
  'Please check your bank.': 'Please check your bank.',
  'ex. 20 digits': 'ex. 20 digits',
  'ex. WeCraft': 'ex. WeCraft',
  Currency: 'Currency',
  'Please select a currency.': 'Please select a currency.',
  'Add a bank': 'Add a bank',
  'List of added bank accounts': 'List of added bank accounts',
  // 'Next': 'Next',
  'Error occured.': 'Error occured.',
  'impossible to delete': 'Unable to delete, statements exist',
  'Return to statements': 'Return to statements',
  'Bank statements': 'Bank statements',
  // ==============================

  // ==============================
  // App - Wizard - Subscription
  // ==============================
  // 'Welcome to Bore!': 'Welcome to Bore!',
  // "Sign up now, it's free and only takes two minutes to manage your accounts.":
  //   "Sign up now, it's free and only takes two minutes to manage your accounts.",
  // ==============================

  // ==============================
  // App - Wizard - Subscription - PricingPlans
  // ==============================
  'Basic plan': 'Basic plan',
  'Business plan': 'Business plan',
  'Enterprise plan': 'Enterprise plan',
  'Per month': 'Per month',
  Start: 'Start',
  Default: 'Default',
  Free: 'Free',
  Trail: 'Trail',
  // ==============================

  // ==============================
  // App - Wizard - Subscription - PaymentForm
  // ==============================
  'Billing Information': 'Billing Information',
  SET_FIRST_COUNTER: 'Set your first counter',
  SET_FIRST_COUNTER_DESC: 'Set your first counter to start your invoices',
  'Firstname *': 'Firstname *',
  Firstname: 'Firstname',
  'Lastname *': 'Lastname *',
  Lastname: 'Lastname',
  'Company Name *': 'Company Name *',
  // 'Company Name': 'Company Name',
  'Country / Region *': 'Country / Region *',
  'Country / Region': 'Country / Region',
  'Postal Code *': 'Postal Code *',
  'Phone *': 'Phone *',
  'Address *': 'Address *',
  Address: 'Address',
  // 'Start': 'Start',
  'Your order': 'Your order',
  VAT: 'VAT',
  Total: 'Total',
  'Pay by credit card with e-dinar card or with your Paymee account.':
    'Pay by credit card with e-dinar card or with your Paymee account.',
  Order: 'Order',
  'Invoice details': 'Invoice details',
  'Your command': 'Your command',
  'Please enter a firstname.': 'Please enter a firstname.',
  'Please enter a lastname.': 'Please enter a lastname.',
  'Please enter a company name.': 'Please enter a company name.',
  'Please enter a country and/or region.':
    'Please enter a country and/or region.',
  'Please enter a valid postal code.': 'Please enter a valid postal code.',
  'Please enter a valid phone number.': 'Please enter a valid phone number.',
  'Please enter an address.': 'Please enter an address.',
  WELCOME_ABOARD: 'Welcome aboard',
  // ==============================

  // ==============================
  // App - Dashboard
  // ==============================
  'Moral Entity': 'Moral Entity',
  'Physical Entity': 'Physical Entity',
  'Find your company': 'Find your company',
  Light: 'Light',
  Dark: 'Dark',
  Performances: 'Performances',
  Receipts: 'Receipts',
  Transactions: 'Transactions',
  Statements: 'Statements',
  Documents: 'Documents',
  Notifications: 'Notifications',
  'PNG, JPG, PDF (max. 900 MB)': 'PNG, JPG, PDF (max. 900 MB)',
  'Upload a file': 'Upload a file',
  Size: 'Size',
  owner: 'Owner',
  DELETE_FILE: 'Delete',
  DOWNLOAD_FILE: 'Download',
  CURRENCY_NOT_CONVERTED: 'Currency detected is',
  THE_TRANSACTION_WILL_BE_CARRIED_OUT_IN_CASH:
    'The transaction will be carried out in cash',
  THE_TRANSACTION_WILL_BE_CARRIED_OUT_IN_CCA:
    'The transaction will be carried out in CCA',
  TRANSACTION_ADDED_SUCCESSFULLY: 'Transaction added successfully',
  FAILED_ADD_TRANSACTION: 'Failed to add transaction',
  DRAG_OR_CLICK_ON_THE_DROPZONE_TO_ADD_YOUR_FILE:
    'Drag or click on the dropzone to add your file. file type must be (CSV)',
  // docs errors
  INVALID_PATH: 'Invalid path',
  INVALID_FILE_NAME: 'Invalid file name',
  FILE_ALREADY_EXISTS: 'File already exists',
  INVALID_REQUEST_FILE_UPLOAD: 'Invalid request file upload',
  NO_FILE_FOUND: 'No file found',
  NO_FOLDER_FOUND: 'No folder found',
  SIZE_LIMIT_EXCEEDED: 'Size limit exceeded',
  USER_NOT_FOUND: 'File not found',
  FILE_IS_LOCKED: 'File is locked',
  FOLDER_IS_LOCKED: 'Folder is locked',
  FOLDER_CANNOT_EMPTY: 'Folder cannot be empty',
  TRANSACTION_INFORMATION: 'Transaction information',
  TRANSACTION_AMOUNT: 'Transaction amount',

  "Can't upload statement": "Can't upload statement",
  "Image can't be loaded, please check your internet connection":
    "Image can't be loaded, please check your internet connection",
  'Add a new statement': 'Add a new statement',
  'Processing error': 'Processing error',
  'Being analyzed': 'Being analyzed',
  'Something went wrong please contact support for help.':
    'Something went wrong please contact support for help.',
  'Contact support': 'Contact support',
  'there is something wrong in end balance or accumulated amount of transactions':
    'There is something wrong with the end balance or the accumulated amount of transactions.',
  'wrong end balance': 'Wrong end balance',
  'Bad bank statement rib': 'Bad bank statement rib',
  'Rib explication tooltip':
    'Your bank details are wrong please check the bank statement',
  'Please check the bank statement you uploaded':
    "Please check the bank statement you uploaded, it seems like you uploaded the wrong one or it's missing important information.",
  'Please check the bank statement date':
    "Please check the bank statement you uploaded, it seems like the statement date doesn't match the cell you chose.",
  'Delete statement': 'Delete statement',
  'Return to transactions': 'Return to transactions',
  'Add Manual Transaction': 'Add Manual Transaction',
  ' Channel ': ' Channel ',
  'Credit/Debit': 'Credit/Debit',
  'Transaction type': 'Transaction type',
  Title: 'Title',
  'Payment channel is required': 'Payment channel is required',
  'ShareholderUser is required': 'ShareholderUser is required',
  'Money flow is required': 'Money flow is required',
  'Title is required': 'Title is required',
  'Transaction date is required': 'Transaction date is required',
  'Amount is required': 'Amount is required',
  'Amount is required in TND': 'Amount is required in TND',
  'Amount must be greater than 0': 'Amount must be greater than 0',
  'Currency is required': 'Currency is required',
  'Add a new transaction': 'Add a new transaction',
  'Successfully Added': 'Successfully Added',
  'The new transaction is now part of the existing list, seamlessly integrated.':
    'The new transaction is now part of the existing list, seamlessly integrated.',
  'Thank you for adding a new transaction':
    'Thank you for adding a new transaction',
  'Add an account': 'Add an account',
  'Refresh receipts': 'Refresh receipts',
  'There is a gap amount of': 'There is a gap amount of',
  Bills: 'Bills',
  'Deleivery Notes': 'Deleivery Notes',
  Products: 'Products',
  'Go to homepage': 'Go to homepage',
  'Delete all': 'Delete all',
  CASH: 'Cash',
  JANUARY: 'January',
  FEBRUARY: 'February',
  MARCH: 'March',
  APRIL: 'April',
  MAY: 'May',
  JUNE: 'June',
  JULY: 'July',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'October',
  NOVEMBER: 'November',
  DECEMBER: 'December',
  'Transaction name': 'Transaction name',
  Current_Year: 'Current year',
  The_previous_month: 'Last month',
  Current_month: 'Current month',
  // ==============================
  // App - Dashboard - Export fille
  // ==============================
  Download: 'Download',
  Completed: 'Completed',
  Files: 'Files',
  'Exported data': 'Exported data',
  'Choice date range': 'Choice date range',
  'Account verification': 'Account verification',
  'Enter your password': 'Enter your password',
  'Auth verification': 'Auth verification',
  'Export fille': 'Export file',
  'Export Data Page': 'Export Data Page',
  EXPORT_HISTORY: 'Export History',
  FORMAT: 'Format',
  REQUESTED_AT: 'Requested At',
  READY: 'Ready',
  // ==============================

  // ==============================
  // App - Accounting - Book Entry
  // ==============================
  'Book entries': 'Book Entries',
  'Piece Number': 'Piece number',
  Journal: 'Journal',
  CategoryCode: 'Category Code',
  Debit: 'Debit',
  Credit: 'Credit',
  'Delete Book Entry': 'Delete book entry',
  'Are you sure you want to delete this book entry?':
    'Are you sure you want to delete this book entry?',
  CONFIRM_BOOK_ENTRY_DELETION:
    'If you click on delete, the book entry will be deleted',
  Update: 'Update',
  'Update Book Entry': 'Update book entry',
  'Add a new line': 'Add a new line',
  'Add Book Entry': 'Add Book Entry',
  label: 'Label',
  DIFFERENCE: 'Difference',
  'There are no Book Entries': 'There are no Book Entries',
  'Seems like the filter you applied have no results, consider resetting or changing the filters.':
    'Seems like the filter you applied have no results, consider resetting or changing the filters.',

  // ==============================
  // App - Accounting - Ledger
  // ==============================
  'Ledger Page': 'Ledger Page',
  'No Ledger found': 'No Ledger found',
  Balance: 'Balance',
  'There are no Ledger entries for this category.':
    'There are no Ledger entries for this category.',
  Ledger: 'Ledger',

  // ==============================
  // App - Accounting - Import Accounting Plan
  // ==============================
  'Import Accounting plan': 'Import Accounting plan',
  'Accounting Plan Page': 'Accounting Plan Page',
  'import accounting plan server error msg':
    'Something went wrong with importing the file. Please try again.',
  BOOKENTRIES_IMPORT_SERVER_ERROR_MSG:
    'Error while importing book entries. Please try again.',
  CATEGORY_CODE_TOO_LONG: 'A category code has exceeded 8 digits',
  EMPTY_CATEGORY_CODE:
    'A category code is empty, please provide a category code for each entry',
  RECORD_TOO_SHORT:
    'An entry is incomplete, please provide a category code and a label for each entry',
  'Erase current accounting plan': 'Erase current accounting plan',
  'No file selected': 'No file selected',
  'Select File': 'Select File',
  'Accounting plan imported successfully':
    'Accounting plan imported successfully',
  BOOKENTRIES_IMPORTED_SUCCESSFULLY: 'Book entries imported successfully',

  // ==============================
  // App - Accounting - Categories Balance
  // ==============================
  CATEGORY_CODE: 'Category code',
  LABEL: 'Label',
  CREDIT: 'Credit',
  DEBIT: 'Debit',
  CREDIT_BALANCE: 'Credit Balance',
  DEBIT_BALANCE: 'Debit Balance',
  BALANCE: 'Balance',
  NO_DATA_FOUND: 'No data found',
  EXPORT: 'Export',
  EXPORT_CSV: 'Export To Csv',
  EXPORT_XLS: 'Export To XLS',

  // ==============================
  // App - Accounting - Fiscal Years
  // ==============================
  'Fiscal year': 'Fiscal year',
  'fiscal year already exists': 'Fiscal year already exists',
  'error while creating the fiscal year':
    'Error while creating the fiscal year',
  'error while locking the fiscal year': 'Error while locking the fiscal year',
  'error while unlocking the fiscal year':
    'Error while unlocking the fiscal year',
  'Fiscal years Page': 'Fiscal years Page',
  'Add New Fiscal Year': 'Add New Fiscal Year',
  'You can create a new fiscal year ': 'You can create a new fiscal year ',
  'Year not valid': 'Year not valid',
  'Year is required': 'Year is required',
  Lock: 'Lock',
  unLock: 'Unlock',
  // ==============================
  // App - Dashboard - Home
  // ==============================
  'Hello, world!': 'Hello, world!',
  // ==============================

  // ==============================
  // App - Dashboard - Drive
  // ==============================

  'deleting folder': 'Deleting folder',
  'folder deleted successfully': 'Folder deleted successfully',
  'you can not move a folder to its subfolder':
    'You can not move a folder to its subfolder',
  'moving folder': 'Moving folder',
  'folder moved successfully': 'Folder moved successfully',
  'downloading folder': 'Downloading folder',
  'folder downloaded successfully': 'Folder downloaded successfully',
  'something went wrong while downloading folder':
    'Something went wrong while downloading the folder',
  'creating folder': 'Creating folder',
  'folder created successfully': 'Folder created successfully',
  'uploading folder': 'Uploading folder',
  'folder uploaded successfully': 'Folder uploaded successfully',
  'deleting file': 'Deleting file',
  'file deleted successfully': 'File deleted successfully',
  'something went wrong while deleting file':
    'Something went wrong while deleting the file',
  'downloading file': 'Downloading file',
  'file downloaded successfully': 'File downloaded successfully',
  'something went wrong while downloading file':
    'Something went wrong while downloading the file',
  'something went wrong while renaming file':
    'Something went wrong while renaming the file',
  'you can not move a file to its current folder':
    'You can not move a file to its current folder',
  'moving file': 'Moving file',
  'file moved successfully': 'File moved successfully',
  'something went wrong while moving file':
    'Something went wrong while moving the file',
  'Delete folder': 'Delete folder',
  'Are you sure you want to delete this folder?':
    'Are you sure you want to delete this folder?',
  'New Folder': 'New Folder',
  'upload Folder': 'Upload Folder',
  Folders: 'Folders',
  Open: 'Open',
  Rename: 'Rename',
  Move: 'Move',
  Delete: 'Delete',
  'No document available in this folder':
    'No document available in this folder',
  Moving: 'Moving',
  Renaming: 'Renaming',
  Creating: 'Creating',
  'upload folder': 'Upload folder',
  'Selected path': 'Selected path',
  docs: 'Docs',
  // ==============================

  //plan info========================

  '50 factures saisies automatiquement gratuit':
    '50 invoices automatically entered for free',
  'Extraction des relevés bancaires 2 pages relevé':
    'Bank statement extraction for 2 pages',
  'Tableau de bord KPI de base': 'Basic KPI dashboard',
  'Centralisation et stockage des documents 10 Go':
    'Centralization and document storage 10 GB',
  'Système de facturation intelligent': 'Intelligent invoicing system',
  'Catégorisation intelligente des transactions, détection automatique de la TVA':
    'Smart transaction categorization, automatic VAT detection',
  'Chat en temps réel': 'Real-time chat',
  'Gestion Clients': 'Customer management',
  'Gestion Fournisseurs​': 'Supplier management',
  Multidevises: 'Multi-currency',
  'Import et Export des données': 'Data import and export',
  'Pièce jointe': 'Attachment',
  'Transfert des factures par mail': 'Invoice transfer by email',
  '3 utilisateurs —> Ajout utilisateur : 30Dinars':
    '3 users —> Additional user: 30 Dinars',
  '100 factures saisies automatiquement gratuit':
    '100 invoices automatically entered for free',
  'Centralisation et stockage des documents illimité':
    'Centralization and unlimited document storage',
  'Toutes les fonctionnalités du Plan Pré-Comptabilité':
    'All features of the Pre-Accounting Plan',
  'Comptabilité : Accès aux écritures comptables et états financiers à tout moment de l’année (mises à jour en temps réel)':
    'Accounting: Access to accounting records and financial statements at any time of the year (real-time updates)',
  'Déclaration fiscale mensuelle automatique : Générée à partir des écritures comptables':
    'Automatic monthly tax declaration: Generated from accounting records',
  "Import et Export des données : Intégration facile avec d'autres systèmes":
    'Data import and export: Easy integration with other systems',
  '4 Utilisateurs —> Ajout utilisateur : 50 Dinars':
    '4 users —> Additional user: 50 Dinars',

  // =============================
  // ==============================
  // App - Settings
  // ==============================
  'Settings (02)': 'Settings',
  Surname: 'Surname',
  'Phone number': 'Phone number',
  Profile: 'Profile',
  Enterprise: 'Enterprise',
  'Profile updated successfully.': 'Profile updated successfully.',
  'Legal Status': 'Legal Status ',

  'Password Page': 'Password Page',
  'Old Password': 'Current password',
  'New Password': 'New Password',
  'Confirm new password': 'Confirm new password',
  'Save changes': 'Save changes',
  Team: 'Team',
  ExportData: 'Data export',
  OcrManagement: 'Page management',
  AddPages: 'Page offers',
  GREATER_THAN_ZERO: 'The number must be greater than 0.',
  // 'Subscription': 'Subscription',
  'Last Access Date': 'Last Access Date',
  'Invite a user': 'Invite a user',
  'Subscription ends on': 'Subscription ends on',
  MONTH: 'Month',
  'Export data page': 'Export data page',
  'Launching the export operation': 'Launching the export operation',
  PAGES_MANAGEMENT: 'Page management',
  PAGES_OFFERS: 'Page offers',
  TRANSFER_HISTORY: 'Transfer history',
  senderId: 'Sender',
  receiverId: 'Receiver',
  numberPagesSent: 'Number of pages sent',
  SEND_PAGES: 'Send pages',
  NO_HISTORY_TRANSFER: 'No transfer history available.',
  TRANSFER_FORM: 'Transfer form',
  nbPages: 'Number of pages',
  TRANSFER: 'Transfer',
  REMBOURSEMENT: 'Reimbursement',
  ENTER_NUMBER_OF_PAGES: 'Enter number of pages',
  PLEASE_ENTER_A_VALID_NUMBER: 'Please enter a valid number',
  TRANSFER_SUCCESS: 'Transfer completed successfully',
  TRANSFER_FAILED: 'Transfer failed. Please try again.',
  PAGES_CREDIT_LIMIT_EXCEEDED:
    'The subscription of the company to which you are transferring pages already has a maximum number of credit pages.',
  'pages OCR (saisie automatique)': 'OCR pages (automatic text recognition)',
  'Pack Essentiel OCR': 'OCR Essentials PackR',
  'Pack Avancé OCR': 'Advanced OCR Pack',
  'Pack Intermédiaire OCR': 'Intermediate OCR Pack',
  // ==============================
  // *******
  Name: 'Name',

  'Please enter a valid name.': 'Please enter a valid name.',

  // ==============================
  // App - Performance
  // ==============================
  Turnover: 'Turnover',
  Charge: 'Expenses',
  'Operating result': 'Operating result',
  Treasury: 'Treasury',
  'Compared to': 'Compared to',
  'last year': 'last year',

  // ==============================
  // App - Receipts
  // ==============================
  Status: 'Status',
  Amount: 'Amount',
  'Amount HT': 'Amount HT',
  DISPLAY: 'Display',
  'Total Amount': 'Total Amount',
  All: 'All',
  'Not associated/analyzed': 'Not associated/analyzed',
  Associated: 'Associated',
  'Not associated': 'Not associated',
  Filter: 'Filter',
  Category: 'Category',
  'Delete filters': 'Delete filters',
  Apply: 'Apply',
  'Duplication error': 'Duplication Error',
  next: 'Next',
  'confirm deletion': 'Confirm deletion',
  'are you sure you want to delete this receipt?':
    'Are you sure you want to delete this receipt?',
  error: 'Error',
  'you can not delete the base duplication':
    "You can't delete the base duplication",
  success: 'Success',
  'Duplication deleted': 'Duplication deleted',
  'Duplication validated': 'Duplication validated',
  'Error validating duplication': 'Error validating duplication',
  'Duplication Receipts': 'Duplication Receipts',
  'receipt already validated': 'Receipt already validated',
  validate: 'Validate',
  Search: 'Search',
  From: 'Supplier',
  Supplier: 'Supplier',
  'Payment Method': 'Payment method',
  totalAmount: 'Total amount',
  'All Taxes Included': 'All taxes included',
  'Add a VAT line': 'Add a VAT line',
  invoiceDate: 'Invoice date',
  vatAmount: 'Vat amount',
  vatTaxAmount: 'Vat tax amount',
  vatTaxRate: 'Vat tax rate  as %',
  'My receipt': 'My receipt',
  Aucune: 'None',
  Aucun: 'None',
  'Not analyzed': 'Not analyzed',
  'No data table': 'No receipts found',
  Max: 'Max',
  Min: 'Min',
  'Add supporting receipts': 'Add supporting receipts',
  FILE_IS_EMPTY: 'The uploaded file is empty.',
  'Next step': 'Next',
  Upload: 'Upload',
  'Document providing physical proof that something has been done':
    'Document providing physical proof that something has been done',
  'error msg pdf':
    'It seems that the pdf of the receipt is not available, try to upload the document again.',
  'No document available in pdf': 'No document available in pdf',
  'error msg image':
    'It seems that the image of the receipt is not available, try to upload the document again.',
  'No image available': 'No image available',
  Downloads: 'Downloads',
  Analysis: 'Analysis',
  Done: 'Done',
  Uploaded: 'Uploaded',
  UPLOADED: 'Uploaded',
  'Your file is not valid.': 'Your file is not valid.',
  'The receipt is validated': 'The receipt is validated',
  THE_RECEIPT_IS_NOT_VALIDATED: 'the receipt is not validated',
  NOT_ASSOCIATED: 'Not associated',
  NOT_ANALYZED: 'Not analyzed',
  ANALYSIS_FAILURE: 'Analysis Failure',
  ANALYZED: 'Analyzed',
  ANALYSIS_SUCCESS: 'Analyzed',
  ANALYSIS_IN_PROGRESS: 'Analysis in progress',
  ANALYSIS_MAY_TAKE_LONGER: 'Analysis may take longer than usual',
  ASSOCIATED: 'Associated',
  VALIDATED: 'Validated',
  NOT_PAID: 'Not paid',
  PARTIALLY_PAID: 'Partially paid',
  PAYMENT_STATUS: 'Payment status',
  PAID: 'Paid',
  Paid: 'Paid',
  SALE: 'Sale',
  'Back to receipts': 'Back to receipts',
  'View in fullscreen': 'View in fullscreen',
  'Sales invoice': 'Sales invoice',
  'Purchase invoice': 'Purchase invoice',
  'Invoice Type': 'Invoice Type',
  TVA: 'VAT',
  'Date not found': 'Date not found',
  DELETE_THIS_RECEIPT:
    'You are about to delete a receipt. Please confirm if you wish to proceed with this action',
  'Delete receipt': 'Delete receipt',
  'Delete receipt - Disabled': 'Delete receipt - Disabled',
  'Delete all receipts': 'Delete all receipts',
  "You're about to delete all the selected receipts, are you sure?":
    "You're about to delete all the selected receipts, are you sure?",
  'date not found': 'Date not found',
  'Ventilate receipt': 'Ventilate receipt',
  Fund: 'Fund',
  Sum: 'Sum',
  'Deductible VAT': 'Deductible VAT',
  'VAT-collected': 'vat-collected',
  'Associates - current accounts': 'Associates - current accounts',
  'No current accounts': 'No current accounts',
  'No vat collected and no vat deductible':
    'No vat collected and no vat deductible',
  'No funds': 'No funds',
  'Not converted': 'Not converted',
  Converted: 'Converted',
  'amount detected': 'Detected amount',
  DETECTED_SUPPLIER: 'Detected supplier',
  RECEIPT_VENTILATION_NOT_FOUND:
    "Validation can't be performed without the category breakdown",
  RECEIPT_CATEGORY_CODE_NOT_FOUND:
    "Validation can't be performed without category",
  RECEIPT_DATE_NOT_FOUND:
    "Validation can't be performed without the invoice date",
  FORBIDDEN_VALIDATION: 'You are not allowed to validate the receipt',
  'There is no receipt suggestion': 'There is no receipt suggestion',
  'You still can search for a receipt using the search bar above':
    'You still can search for a receipt using the search bar above',
  'or get all receipts': 'or get all receipts',
  'Are you sure to unlock this receipt ?':
    'Are you sure to unlock this receipt ?',
  'You are about to unlock this receipt, please confirm your action.':
    'You are about to unlock this receipt, please confirm your action.',
  'The validation is canceled': 'The validation is canceled',
  'Amount not found': 'Amount not found',
  'Updating the amount from the receipt settings':
    'Update amount from receipt settings',
  'invoiceDate is a required field': 'Invoice date is a required field',
  'totalAmount is a required field': 'Total amount is a required field',
  SUPPLIER_NOT_FOUND: 'Supplier not found',
  'Receipt updated successfully': 'Receipt updated successfully',
  'Add a new payment action': 'Add a new payment action',
  Source: 'Source',
  'Payment flow': 'Payment flow',
  'All the amounts must be in TND': 'All the amounts must be in TND',
  DATE_CONFUSION: 'the operation date does not correspond to the invoice date',
  ADD_New_PAYMENT_ACTION: 'Add a new payment action',
  PAYMENT_FLOW: 'Payment flow',
  ALL_AMOUNT_MUST_BE_IN_TND: 'All the amounts must be in TND',
  UNKOWN_SOURCE: 'Unkown source',
  ADD_A_New_CLIENT: 'Add New Client',
  UPDATE_CLIENT_MESSAGE: 'Client updated successfully.',
  ADD_NEW_SUPPLIER: 'Add New Supplier',
  RECEIPT_INVOICE_DATE_FISCAL_YEAR_WRONG:
    'The invoice does not correspond to the correct fiscal year',
  MISSING_FIELDS: 'Missing data',
  transactionDate: 'Transaction date',
  amount: 'Amount',
  name: 'Name',
  NAME_TRANSACTION: 'Transaction of',
  CANCEL_INVOICE: 'Cancel invoice',
  CANCEL_INVOICE_MESSAGE:
    'This action is irreversible and will permanently remove the invoice from your records. Are you certain you want to proceed?',
  THE_SUM_OF_THE_AMOUNT_SHOULD_BE_THE_SAME:
    'The sum of the amount should be the same as the transaction amount.',
  RECEIPT_ALREADY_VALIDATED_BY_ACCOUNTANT:
    'Receipt already validated by accountant',
  receipts: 'Receipts',
  EMAIL_ALIAS_INFO:
    'You can transfer or send your receipts to this address and they will be automatically added to your account.',
  SPLITTING_SHORTCUTS: 'Splitting Shortcuts',
  SPLITTING_SHORTCUTS_INFO:
    'You can quickly split your document using the following patterns.',
  ONE_PAGE_PER_RECEIPT: 'One page per receipt',
  TWO_PAGES_PER_RECEIPT: 'Two pages per receipt',
  THREE_PAGES_PER_RECEIPT: 'Three pages per receipt',
  Shortcuts: 'Shortcuts',
  FILE_SPLIT_SUCCESSFULLY: 'File split successfully',
  FILE_SPLIT_ERROR: 'Error while splitting the file',
  UPLOAD_MULTI_RECEIPT_FILE: 'Upload multi-receipt file',
  // ==============================
  // App - Transactions
  // ==============================
  Filters: 'Filters',
  Sort: 'Sort',
  by: 'by',
  Date: 'Date',
  'Sale of': 'Sale of',
  Service: 'Service',
  'Operations to validate': 'Operations to validate',
  'Validated operations': 'Validated operations',
  Operations: 'Operations',
  'Change my plan': 'Change my plan',
  'Validate the operation': 'Validate the operation',
  'To categorize': 'To categorize',
  Invalidate: 'Invalidate',
  Invalidated: 'Not validated',
  'Validate by Owner': 'Validate by Owner',
  'Validate by accoutant': 'Validated by the accountant',
  'The transaction is validated': 'The transaction is validated',
  'The transaction is invalidated': 'transcation validation is cancelled',
  'Error validating transaction': 'Error validating transaction',
  'Account balances': 'Account balances',
  'Software subscription': 'Software subscription',
  'Deposit received': 'Deposit received',
  'VAT paid': 'VAT paid',
  'To choose': 'To choose',
  Perhaps: 'Perhaps?',
  'This receipt is already fully paid': 'This receipt is already fully paid',
  'Import transactions': 'Import transactions',
  'Drop CSV file here or click to upload':
    'Drop CSV file here or click to upload',
  'Matching columns': 'Matching columns',
  Column: 'Column',
  fileColumns: 'File columns',
  Verification: 'Verification',
  verificationReport: 'Verification report',
  'Report on transactions in your file.':
    'Report on transactions in your file.',
  'Total errors': 'Total errors',
  'Number of warnings': 'Number of warnings',
  'you have transactions for which information we consider important is missing from your file.':
    'You have transactions for which information we consider important is missing from your file.',
  'You have existing transactions': 'You have existing transactions',
  'as well as others with a transaction date within a month for which you have a bank statement.':
    'as well as others with a transaction date within a month for which you have a bank statement.',
  'For additional information, please find below the transactions concerned.':
    'For additional information, please find below the transactions concerned.',
  'You can continue to import your transactions.':
    'You can continue to import your transactions.',
  'duplicated transactions': 'Duplicated transactions',
  'transactions that are missing some information':
    'Transactions that are missing some information',
  'transactions with an existing month': 'Transactions with an existing month',
  'Delete transaction': 'Delete transaction',
  "You're about to delete this transaction, are you sure ?":
    "You're about to delete this transaction, are you sure ?",
  'import transaction': 'import transaction',
  'you still have errors in your report':
    'You still have errors in your report',
  PENDING: 'Pending',
  VALIDATED_OWNER: 'Validated by owner',
  VALIDATED_ACCOUNTANT: 'Validated',
  'Delivery Notes': 'Delivery Notes',
  'Category code': 'Category code',
  'Delete Client': 'Delete client',
  DELETE_PRODUCT: 'Delete product',
  Issuer: 'Issuer',
  'Operation Date': 'Operation date',
  OF: 'of',
  TX_VALIDATED_BY_ACCOUNTANT: 'This Transaction is validated by the Accountant',
  TX_VALIDATED_BY_OWNER: 'This Transaction is validated by the Owner',
  TX_ATTACHED_RECEIPT_VALIDATION_ERROR:
    'An attached receipt cannot be validated',
  TX_CATEGORIES_REQUIRED: 'You have to categorize the transaction first',
  VENTILATIONS_EMPTY: 'The ventilations are empty',
  VENTILATION_GAP_EXISTS: 'There is a gap in the ventilations',

  //================================
  //REFERRAL
  //================================
  REFERRAL: 'Referral',
  SEND: 'Send',
  COPY: 'Copy',
  COPIED: 'Copied',
  INVITE_YOUR_CUSTOMERS_IN_3_EASY_STEPS:
    'Invite Your Customers in 3 Easy Steps',
  INVITATION: 'Invitation',
  REGISTRATION: 'Registration',
  MONTH_FREE: '1 Month Free',
  SEND_YOUR_UNIQUE_REFERRAL:
    'Send your unique referral link to your friends by e-mail, on social networks or by direct message.',
  YOUR_FRIENDS_SIGN_UP:
    'Your friends sign up using your link and start exploring the platform.',
  FROM_THEIR_FIRST_ACTION: 'From their first action, you get one month free!',
  ADD_YOUR_FRIENDS_EMAIL_ADDRESS: 'Add your friend’s email address',
  ENTER_THE_EMAIL_ADDRESS_TO_SEND_A_DIRECT_INVITATION:
    'Enter the email address to send a direct invitation',
  OR_COPY_YOUR_REFERRAL_LINK: 'Or copy your referral link',
  SHARE_YOUR_UNIQUE_LINK_WITH_A_SINGLE_CLICK:
    'Share your unique link with a single click',
  //================================
  //REFERRAL
  //================================

  // ==============================
  // App - Chat
  // ==============================
  Close: 'Close',
  SEEN: 'Seen',
  DELIVERED: 'Delivered',
  'Delete the last message': 'Delete the last message',
  "You're about to delete the last message, are you sure?":
    "You're about to delete the last message, are you sure?",
  Messages: 'Messages',
  NO_MESSAGES_SELECTED: 'No messages selected',
  SELECT_A_CHAT_FROM_YOUR_MESSAGES_LIST:
    'Select a chat from your messages list',
  NO_DISCUSSION_FOR_THE_MOMENT: 'No discussion for the moment',
  YOUR_INBOX_IS_EMPTY_START_A_NEW_CHAT: 'Your inbox is empty Start a new chat',
  'Search your chat': 'Search your chat',
  Current: 'Current',
  Archived: 'Archived',
  ARCHIVE_THE_CURRENT_CHAT:
    'Archive this conversation? It will move to your archives and remain accessible. Do you intend to continue?',
  ARCHIVE_THIS_DISCUSSION: 'Archive this discussion',
  Archive: 'Archive',
  WRITE_A_MESSAGE: 'Write a message ...',
  'About the transaction': 'About the transaction',
  'Transaction Date': 'Transaction Date',
  'Transaction Type': 'Transaction Type',
  Chats: 'Chats',
  new: 'new',
  categoryCode: 'category code',
  invoicesNumber: 'Number of invoices',
  invoiceNumber: 'Invoice number',
  paymentPending: 'Payment pending',
  salesInvoiced: 'Sales invoiced',
  'About the declaration': 'About the declaration',
  Unassign: 'Unassign',
  Assign: 'Assign',
  YouHaveBeenTaggedOnChat: 'You have been tagged on chat:',

  // 'Team': 'Team',
  // 'Subscription': 'Subscription',
  // ==============================

  // ==============================
  // App - Other - Global Error Handler
  // ==============================
  'Server error. Please try again later.':
    'Server error. Please try again later.',
  'Service Unavailable. Please try again later.':
    'Service Unavailable. Please try again later.',
  Unauthorized: 'Unauthorized',
  "You don't have permissions to access this resource.":
    "You don't have permissions to access this resource",
  'Timeout error': 'Timeout error',
  // ==============================

  // ==============================
  // App - Other - Error Boundary
  // ==============================
  'Unexpected Error Occured': 'Unexpected Error Occured',
  // ==============================

  // ==============================
  // App - Other - Subscription Expired
  // ==============================
  'Subscription Expired': 'Subscription Expired',
  'Subscription Expired Description':
    'Your subscription has expired, please click on Renew my subscription to reactivate your account.',
  'Renew My Subscription': 'Renew my subscription',
  'Subscription Expiry Approaching':
    'Your subscription is about to expire, please click on Renew my subscription.',
  'Subscription Days Left': 'Expires in {{days}} days',
  'Expires in less than 1 day': 'Expires in less than 1 day',
  // ==============================

  // ==============================
  // App - Other - Not Found
  // ==============================
  'Page Not Found': 'Page Not Found',
  // ==============================

  // App - Handle errors
  // ==============================
  AUTH_SIGNIN_BAD_CREDENTIALS: 'The email or password is incorrect',
  AUTH_SIGNIN_USER_LOCKED: 'This account is currently locked',
  AUTH_SIGNIN_USER_NOT_ACTIVATED: 'This account is not activated',
  AUTH_SIGNIN_INVALID_RECAPTCHA_TOKEN:
    'The captcha token is invalid or expired',
  AUTH_EMAIL_ALREADY_EXISTS: 'This email is already used in another account',
  AUTH_SIGNUP_EMAIL_ALREADY_EXISTS:
    'This email is already used in another account',

  INTERNAL_ERROR: 'An internal server error has occurred',
  COMPANY_COMPANY_EXIST: 'Company already exist',
  COMPANY_ID_NOT_FOUND: 'User not found',
  COMPANY_INVALID_CREATE_COMPANY_REQUEST:
    'Something went wrong, please recheck your company information.',
  SETTINGS_ID_MEMBER_NOT_FOUND: 'The member is not found.',
  SETTINGS_INVITATION_EXISTS:
    'An invitation has already been sent to the provided email',
  SETTINGS_MEMBER_EXISTS: 'A member with the provided email already exists',
  SETTINGS_COMPANY_NOT_FOUND: 'The company is not found.',
  SETTINGS_ID_NOT_FOUND: 'The user id does not belong to any account',
  UNAUTHORIZED_UNAUTHORIZED: 'Unauthorized access',
  'Oops! Page not found': 'Oops!\n Page not found',
  "This page doesn't exist or get removed. We suggest you back to home":
    "This page doesn't exist or get removed. We suggest you go back home.",
  'Go back home': 'Go back home',
  'Access denied': 'Oops!\n Access denied',
  "You don't have permission to access requested page.":
    "You don't have permission to access requested page. We suggest you go back home.",
  'Oh no!': 'Oh no!',
  'So sorry, something not working well please try again later or feel free to contact support.':
    'So sorry, something not working well please try again later or feel free to contact support.',
  'Something went wrong': 'Something went wrong',
  ADD_A_NEW_MEMBER:
    'Invite your colleagues to join your workspace to strengthen collaboration.',
  ADD_A_NEW_CLIENT_DESCRIPTION:
    'Please fill in the required fields in order to add a new client to your list.',
  UPDATE_CLIENT_DESCRIPTION:
    'Please fill in the required fields in order to update your client information',
  ADD_A_NEW_PRODUCT_DESCRIPTION:
    'Enter your product details to make it available in your list.',
  THERE_ARE_NO_CLIENT: 'There are no clients',
  THERE_ARE_NO_PRODUCT: 'There are no Products',
  YOU_CAN_ADD_A_NEW_CLIENT_BY_CLICKING_THE_BUTTON_ABOVE:
    'you can add a new client by clicking the button above',
  YOU_CAN_CREATE_A_NEW_PRODUCT_BY_CLICKING_IN_THE_BUTTON_ABOVE:
    'You can create a new product by clicking in the button above',
  // ==============================
  // Month
  // ==============================
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',

  // ==============================
  // accounting
  // ==============================
  active: 'Active',
  pending: 'Pending',
  stable: 'Stable',
  unstable: 'Unstable',
  'Add credit': 'Add credits',
  'Credit price': 'Credit price',
  'Add a new ventilation': 'Add a new ventilation',
  'TVA amount': 'TVA amount',
  Companies: 'Companies',
  Missing: 'Missing',
  Validated: 'Validated',
  'Not validated': 'Not validated',
  'many uploaded': 'uploaded',

  // ==============================
  // General translations
  // ==============================
  'Drag & drop': 'Drag & drop',
  Dashboard: 'Dashboard',
  Settings: 'Settings',
  Accounting: 'Accounting',
  JOIN_DATE: 'Join date',
  CREATION_DATE: 'Creation date',
  CLOSING_DATE: 'Closing date',
  'Sign out': 'Sign out',
  'Validated by owner': 'Validated by owner',
  'Validated by accountant': 'Validated by accountant',
  'Unjustified operations': 'Unjustified operations',
  'There are no transactions': 'There are no transactions',
  'Seems like the filter you applied have no results, consider resetting or changing the filters':
    'Seems like the filter you applied have no results, consider resetting or changing the filters',
  filter: 'filter',
  'Clear filter': 'Clear filter',
  Currencies: 'Currencies',
  Select: 'Select',
  'Select category': 'Select category',
  'Select All': 'Select All',
  Receipt: 'Receipt',

  TO: 'to',
  'Global statistics': 'Global statistics',
  'Bank balance': 'Bank balance',
  'Associated Current Account': 'Associated Current Account',
  'No data found in your search on this page':
    'No data found in your search on this page',
  RIB: 'RIB',
  Label: 'Label',
  "The date doesn't match": 'The date does not match',
  'Total amount': 'Total amount',
  'Original amount': 'Original amount',
  'Go back to receipts': 'Go back to receipts',
  'Go back to transactions': 'Go back to transactions',
  'Category - Ventilation': 'Category - Ventilation',
  'You received an invite': 'You received an invite',
  'has invited you to join': 'has invited you to join',
  'invites you to join': 'invites you to join',
  on: 'on',
  'Invitation date': 'Invitation date',
  welcomeToUvey: 'Welcome to Uvey!',
  invitationMessage:
    'You have been invited to join Uvey. By accepting this invitation, you will gain access to exclusive features of our solution for simplified accounting management and optimized collaboration.',
  benefit1: 'Use Uvey to facilitate your accounting management',
  benefit2: "Access the company's financial and accounting data",
  benefit3: 'Easily collaborate with the existing team',
  howToStart: 'How to get started?',
  createAccount: 'Create your account or log in if you already have one',
  followSteps: 'Follow the steps to complete your access',
  teamWaiting: 'Your team is eagerly waiting for you!',
  acceptInvitation: 'Accept the invitation',
  contactSupport:
    'If you have any questions or need assistance, our team is available at ',
  orCall: 'or call',
  organization: 'organization',
  "We're delighted to welcome you to a new era of simplified, efficient accounting management.":
    "We're delighted to welcome you to a new era of simplified, efficient accounting management.",
  "Your Digital Accounting Partner designed to simplify and automate your company's financial management.":
    "Your Digital Accounting Partner designed to simplify and automate your company's financial management. By registering, you're taking the first step towards a modern, intuitive accounting experience perfectly tailored to your needs.",
  'To ensure the security of your account, please enter your password below.':
    'To ensure the security of your account, please enter your password below. This allows us to confirm your identity and ensure secure access to your information.',
  'To personalize your export experience, please select a specific date for which you wish to export your accounting data.':
    'To personalize your export experience, please select a specific date for which you wish to export your accounting data.',
  'invitation description':
    "Congratulations! You have received an invitation to join Uvey. By accepting this invitation, you'll gain access to all the exclusive features and benefits offered by our Solution.\n We look forward to welcoming you to Uvey. If you have any questions or require assistance, please do not hesitate to contact us.",
  'Your data export operation may take some time. An email will be sent to you with a link to download your files as soon as they are ready.':
    'Your data export operation may take some time. An email will be sent to you with a link to download your files as soon as they are ready.',
  "This data export operation enables you to retrieve all your company's receipts and bank statements, as well as your accounting export.":
    "This data export operation enables you to retrieve all your company's receipts and bank statements, as well as your accounting export.",
  'Follow the instructions to create your account or log in if you already have one.':
    'Follow the instructions to create your account or log in if you already have one.',
  'Start enjoying the exclusive benefits reserved for UVEY members.':
    'Start enjoying the exclusive benefits reserved for UVEY members.',
  'We are delighted to offer you a range of plans meticulously designed to meet your accounting management needs.':
    'We are delighted to offer you a range of plans meticulously designed to meet your accounting management needs.\n We invite you to take a close look at our offerings and choose the plan that best suits your business needs.',
  'Congratulations! Your payment has been processed successfully. Thank you for placing your trust in Uvey. You can now take full advantage of all the premium features of our service.':
    'Congratulations! Your payment has been processed successfully. Thank you for placing your trust in Uvey. You can now take full advantage of all the premium features of our service.',
  'Congratulations! Your data export is now complete. You can download the file containing all your financial information by clicking on the link below. If you have any questions or require further assistance, please do not hesitate to contact us.':
    'Congratulations! Your data export is now complete. You can download the file containing all your financial information by clicking on the link below. If you have any questions or require further assistance, please do not hesitate to contact us.',
  Add: 'Add',
  // ==============================
  // Accountant & User Declaration
  // ==============================
  Type: 'Type',
  PAYED_OUTDATED: 'Payed outdated',
  PAYED: 'Payed',
  DECLARED: 'Declared',
  DRAFT: 'Draft',
  Draft: 'Draft',
  CANCELLED: 'Cancelled',
  Canceled: 'Cancelled',
  'No Uploaded Files': 'No uploaded files',
  Attachments: 'Attachments',
  Notes: 'Notes',
  'Payed by': 'Payed by',
  Paiment: 'Paiment',
  'Declared by': 'Declared by ',
  Cancel: 'Cancel',
  Save: 'Save',
  DECLARED_OUTDATED: 'Declared outdated',
  'No notes': 'No notes',
  'Legal entity': 'Legal entity',
  Individuals: 'Individuals',
  'declaration canceled': 'Declaration canceled',
  'due date': 'Due date',
  'not payed': 'Social declaration',
  'must be payed': 'Financial declaration',
  Declarations: 'Declarations',
  Uncancel: 'Uncancel',
  'You are in the process of uncanceling this declaration':
    'You are in the process of uncanceling this declaration ',
  'Uncancel declaration': 'Uncancel declaration',
  'Go back to declarations': 'Go back to declarations',
  Declaration: 'Declaration',
  'for the entreprise ': 'for the entreprise ',
  'declaration title': 'Title',
  'declaration status': 'Status',
  'declaration declared when': 'Declared When',
  'declaration payed when': 'Payed When',
  'This declaration in over its due date':
    'The deadline for this declaration is past.',
  late: 'Late',
  'this declaration has not been treated yet':
    'This declaration has not been treated yet',
  'this declaration has been cancelled': 'This declaration has been cancelled',
  'this declaration has not been declared yet':
    'This declaration has not been declared yet',
  'this declaration has not been payed yet':
    'This declaration has not been payed yet',
  'this declaration does not need to be payed':
    'This declaration does not need to be payed',
  Billing: 'Billing',
  'Create new invoice': 'Create new invoice',
  UNSUPPORTED_FILE_TYPE: 'Unsupported file type',
  DECLARATION_DATE_MUST_BE_BEFORE_PAY_DATE:
    'The declaration date must be before the payment date',
  'This product will be used only once, no need to save it.':
    'This product will be used only once, no need to save it.',
  'Ventilation is required, please ventilate this receipt':
    'Ventilation is required, please ventilate this receipt',
  "Feel free to add the category you want, and make sure your category code follows our requirements, you can't create a category with an existing category code.":
    "Feel free to add the category you want, and make sure your category code follows our requirements, you can't create a category with an existing category code.",
  UPDATE_RECEIPT: 'Update receipt',
  FOLDER_NAME_REQUIRED: 'Folder name is required',
  FOLDER_CREATING: 'Give your folder a meaningful name',
  NAME_ALREADY_EXISTS: 'Name already exists, please choose another name',
  SOMETHING_WENT_WRONG: 'Something went wrong, please try again.',
  ONCE_THE_INVOICE_IS_VALIDATED:
    'Once the invoice is validated, it can no longer be deleted',
  ONCE_THE_INVOICE_IS_VALIDATED_UPDATED:
    'Once the invoice is validated, it can no longer be updated',
  INVOICE_IS_DRAFT_DELETE: 'You cannot cancel a draft',
  CODE_ALREADY_EXISTS: 'Client code already exists.',
  TAX_ALREADY_EXISTS: 'Client tax number already exists.',
  CLIENT_INFORMATION: 'Client information',
  Product_Details: 'Product details',
  ADD_A_NEW_PRODUCT: 'Add a new product',
  Price: 'Price',
  Unit: 'Unit',
  // ==============================
  // UNITS
  // ==============================
  TON: 'ton',
  METER: 'Meter',
  SQUARE_METER: 'Square meter',
  CUBIC_METER: 'Cubic meter',
  TRIMESTER: 'Trimester',
  LITER: 'Liter',
  PIECE: 'Piece',
  DAY: 'Day',
  HOUR: 'Hour',
  NONE: 'None',
  // ==============================
  // Billings
  // ==============================
  ADD_A_NEW_CLIENT_TITLE: 'Add a new client',
  TAX_ID: 'Tax Number',
  UPDATE_CLIENT_TITLE: 'Update Client',
  CLIENT_CLIENT_MESSAGE: 'The client has been deleted.',
  CLIENT_CLIENT_ADD: 'Client created successfully.',
  UPDATE_PRODUCT_TITLE: 'Update Product',

  CATEGORY_CODE_REQUIREMENT:
    "Category code should contain 411 and it's length should be grater than 3 and less than 8",
  Stamp: 'Stamp',
  EMAIL_SUBJECT: 'Email subject',
  EMAIL_BODY: 'Email body',
  EMAIL_TEMPLATE_DESCRIPTION:
    'This section is used to configure the e-mail template sent with sales invoices',
  SAVE_SUCCESSFUL_EMAIL_TEMPLATE:
    'successful registration of the e-mail template',
  INVOICE_VALIDATED_SUCCESSFULLY: 'Bill validated successfully',
  INVOICE_FINALIZED_SUCCESSFULLY: 'Bill finalized successfully',
  YOU_ARE_ABOUT_TO_DELETE_THIS_CLIENT:
    'You are about to delete a client. Please confirm if you wish to proceed with this action.',
  BACK_TO: 'Back to ',
  Preview: 'Preview',
  CREATE_INVOICE: 'Create invoice',
  ISSUE_DATE: 'Issue date',
  DEADLINE: 'Deadline',
  Finalize: 'Finalize',
  DUE_DATE: 'Due date',
  CREATE_A_NEW_PRODUCT: 'Create a new product',
  UPDATE_FOOTER: 'Update the footer',
  APPLY_NUMBER_TO_CHARACTER: 'Display amount in words',
  ADD_DESCRIPTION: 'Add description',
  ADD_NOTE: 'Add note',
  APPLY_DISCOUNT: 'Apply discount',
  CURRENCY_IS_REQUIRED: 'Currency is required',
  VAT_DETAILS: 'Tax Details',
  VAT_AMOUNT: 'Tax amount',
  VAT_RATE: 'Tax rate',
  FOOTER_UPLOADED_SUCCESSFULLY: 'Footer uploaded successfully',
  FOOTER_UPLOAD_FAILED: 'Footer upload failed',
  Base_HT: 'tax-free basis',
  GLOBAL_DISCOUNT: 'Global Discount',
  Summary: 'Summary',
  'Total before tax': 'Total before tax',
  'Total after discount': 'Total after discount',
  Total_VAT: 'Total tax',
  'Total after tax': 'Total after tax',
  SPECIAL_NOTES: 'Special notes',
  'Document generated by': 'Document generated by',
  SEND_TO_INVOICES: 'Send to invoices',
  SEND_TO_STATEMENT: 'Send to statements',
  STATEMENT_CREATED_SUCCESSFULLY: 'Statement created successfully',
  RECEIPT_CREATED_SUCCESSFULLY: 'Receipt created successfully',
  PRODUCT_CREATED_SUCCESSFULLY: 'Product created successfully.',
  FILE_NAME_NOT_FOUND: 'File name is not found',
  Product: 'Product',
  Quantity: 'Quantity',
  PRICE_UNIT: 'Unit price',
  'Price before tax': 'Price before tax',
  DOCUMENT_CREATED_SUCCESSFULLY: 'Document created successfully',
  Quote: 'Quote',
  'Quote Number': 'Quote Number',
  'Delivery note': 'Delivery note',
  'Delivery note Number': 'Delivery note Number',
  Quotes: 'Quotes',
  'Delivery notes': 'Delivery notes',
  Unarchived: 'Unarchived',
  IN_PROGRESS: 'In progress',
  INVOICE_ZERO_AMOUNT: 'Invoice amount cannot be zero',
  YOU_ARE_ABOUT_TO_DELETE_THIS_DELIVERY_NOTE:
    'Do you really intend to delete the selected delivery note?',
  YOU_ARE_ABOUT_TO_DELETE_THIS_QUOTE:
    'Do you really intend to delete the selected quote?',
  'you are about to archive this delivery note, are you sure?':
    'You are about to archive this delivery note, are you sure?',
  YOU_ARE_ABOUT_TO_ARCHIVE_THIS_QUOTE:
    'Do you really intend to archive the selected quote?',
  'The invoice has been duplicated': 'The invoice has been duplicated',
  'The quote has been duplicated': 'The quote has been duplicated',
  'The delivery note has been duplicated':
    'The delivery note has been duplicated',
  Edit: 'Edit',
  Review: 'Review',
  Duplicate: 'Duplicate',
  NO_SUPPLIERS_YET: 'No Suppliers Yet',
  SUPPLIERS_ARE_CREATED_AUTOMATICALLY:
    'Suppliers are created automatically when you add purchase invoices or import your chart of accounts.',
  DELETE_INVOICE: 'Delete invoice',
  DELETE_QUOTE: 'Delete quote',
  DELETE_DELIVERY_NOTE: 'Delete delivery note',
  DELETE_MESSAGE_DELIVERY_NOTE: 'Delivery note deleted successfully.',
  DELETE_MESSAGE_QUOTE: 'Quote deleted successfully.',
  DELETE_MESSAGE_INVOICE: 'Invoice deleted successfully.',
  THERE_IS_NO_INVOICE: 'There is no invoice',
  ACCOUNTANT_ROLE_CAN_ONLY_INVITE_OTHER_ACCOUNTANT_ROLE:
    'Accountant role can only invite other accountant role',
  'There is no quote': 'There is no quote',
  'There is no delivery note': 'There is no delivery note',
  YOU_ARE_ABOUT_TO_DELETE_THIS_INVOICE:
    'Do you really intend to delete the selected quote?',
  'You can create a new invoice by clicking on the button above':
    'You can create a new invoice by clicking on the button above',
  'You can create a new delivery note by clicking on the button above':
    'You can create a new delivery note by clicking on the button above',
  'You can create a new quote by clicking on the button above':
    'You can create a new quote by clicking on the button above',
  'You can add a new invoice by clicking on the button above':
    'You can add a new invoice by clicking on the button above',
  'You can add a new delivery note by clicking on the button above':
    'You can add a new delivery note by clicking on the button above',
  'You can add a new quote by clicking on the button above':
    'You can add a new quote by clicking on the button above',
  'The delivery note has been transformed into bill':
    'The delivery note has been transformed into bill',
  'Transform into delivery note': 'Transform into delivery note',
  'transform into bill': 'Transform into bill',
  QUOTE_TRANSFORMED: 'The quote has been transformed',
  QUOTE_ARCHIVED: 'The quote has been archived',
  DELIVERY_NOTE_ARCHIVED: 'The delivery note has been archived',
  'Create new quote': 'Create new quote',
  'Create new delivery note': 'Create new delivery note',
  'Archive Quote': 'Archive Quote',
  'Archive Delivery Note': 'Archive Delivery Note',
  WITH_HOLDING_TAX: 'Withholding tax',
  INVOICE_COLOR: 'Primary preview color',
  DELIVERY_NOTE: 'Delivery Notes',
  Suppliers: 'Suppliers',
  BASIS_SUBJECT_TO_VAT: 'Base subject to tax',
  // ==============================
  REACHED_THE_END_OF_THE_PAGE: "Looks like you've reached the end of the page",
  ADD_A_NEW_FOLDER:
    'You can add or create a new folder by clicking in the button above',
  NO_DOCUMENT_FOUND: 'No document found',
  INVOICE_UPDATED_SUCCESSFULLY: 'Invoice updated successfully.',
  QUOTE_UPDATED_SUCCESSFULLY: 'Quote updated successfully.',
  DELIVERY_NOTE_UPDATED_SUCCESSFULLY: 'Delivery note updated successfully.',
  UPLOADING_FILES: 'Uploading Files',
  FILE_UPLOADED_SUCCESSFULLY: 'File uploaded successfully',
  LATE: 'Late',
  DECLARED_BY: 'Declared by',
  PAYED_BY: 'Payed by',
  PAYED_AT: 'Payed at',
  PAYMENT_TYPE: 'Payment type',
  SAVE_THE_CHANGES: "Don't forget to save your changes",
  DISABLED: 'Disabled',
  RECEIPT_VALIDATED: 'This receipt is validated',
  INIT: 'Pending',
  ARCHIVED: 'Archived',
  INVOICE_DETAILS: 'Invoice details',
  RECEIPT_VENTILATION: 'Receipt ventilation',
  ATTACHMENTS: 'Attachments',
  EMPTY_PAYMENT: 'Payment flow is empty',
  EMPTY_VENTILATION: 'Ventilation is empty',
  SELECT: 'Select',
  EMPTY_VENTILATION_DESCRIPTION:
    'You can create a new ventilation by clicking the button above or assign a new receipt.',
  No_Files: 'No files founds',
  UPLOAD_FILE:
    'You can add supporting documents to the invoice by clicking on the button located at the top right of this section.',
  TVA_PERCENTAGE: 'TVA Percentage',
  RECEIPT_ARE_VALIDATED: 'Receipt is validated',
  COMING_SOON: 'Coming soon',
  GAP_AMOUNT: 'Wrong ventilation',
  RECEIPT_VENTILATION_NOT_EQUILIBRATED: 'Receipt ventilation not equilibrated',
  EMPTY_INBOX: 'Empty inbox',
  DECLARED_ON: 'Declared on',
  EXPORT_DATA: 'Export data',
  ACCOUNTING_PLAN: 'Accounting plan',
  Number: 'Number',
  FISCAL_YEARS: 'Fiscal years',
  IMPORT_ACCOUNTING_PLAN: 'Import accounting plan',
  IMPORT_BOOKENTRIES: 'Import book entries',
  CATEGORY_BALANCE: 'Category Balance',
  PROFILE_PAGE: 'Profile Page',
  PROFILE_AVATAR: 'Profile avatar',
  CHOOSE_FILE: 'Choose file',
  User: 'User',
  OWNER: 'Owner',
  USER: 'User',
  ACCOUNTANT: 'Accountant',
  CONFIRM: 'Confirm',
  MY_CURRENT_PLAN: 'My current plan',
  BUY_PAGES: 'Add pages',
  Purchase: 'Purchase',
  PURCHASE: 'Purchase',
  'Amount before discount': 'Amount before discount',
  Discount: 'Discount',
  'Amount after discount': 'Amount after discount',
  'Total net to pay': 'Total net to pay',
  'Select Type': 'Select Type',
  INDIVIDUAL: 'Individual',
  COMPANY: 'Company',
  MANAGER_NAME: "Manager's name",
  UPLOADING: 'Uploading',
  LOADING: 'Loading',
  ADD_A_NEW_CATEGORY: 'Add a new category',
  REQUIRED_FIELDS:
    'Fields marked with ( * ) are required. Please ensure all required fields are completed before submitting.',
  UPDATE_CATEGORY: 'Update category',
  ADD_NEW_CLIENT: 'Add new client',
  SEND_MAIL_TO_THE_CLIENT: 'Send Mail to the Client',
  SUPPLIER_IS_REQUIRED: 'Supplier is required',
  NOT_PAYED: 'Not payed',
  PARTIALLY_PAYED: 'Partially payed',
  SAVE_CHANGES: 'Save changes',
  RECEIPT_DELETED_SUCCESSFULLY: 'Receipt deleted successfully',
  DELETE_ATTACHMENT: 'Delete attachment',
  YOURE_ABOUT_TO_DELETE_THIS_ATTACHMENT:
    "You're about to delete this attachment, are you sure?",
  "YOU'RE_ABOUT_TO_DELETE_THIS_MEMBER":
    "You're about to delete this member (invite), are you sure?",
  PLEASE_ENTER_TAX_ID: 'Please enter tax Id',
  SELECT_COMPANY_TYPE: 'Select client type',
  PLEASE_SELECT_COMPANY_TYPE: 'Please select the client type',
  PLEASE_ENTER_EMAIL: 'Please enter Email',
  PLEASE_ENTER_LABEL: 'Please enter label',
  PLEASE_ENTER_PRODUCT_LABEL: 'Please enter product label',
  PLEASE_ENTER_AMOUNT: 'Please enter an amount',
  PLEASE_ENTER_DESEGNATION: 'Please enter a designation',
  PLEASE_ENTER_ADDRESS: 'Please enter address',
  PLEASE_ENTER_PHONE_NUMBER: 'Please enter phone number',
  SEARCH_BY_CODE_OR_LABEL_NAME: 'Search by code or label name',
  VENTILATION: 'Ventilation',
  TRANSACTION_UPDATED_SUCCESSFULLY: 'Transaction updated successfully',
  YOU_CANT_VALIDATE_THIS_TRANSACTION: 'You cant validate this transaction',
  THERE_IS_A_GAP_IN_THE_AMOUNT:
    'Wrong ventilation, There is a gap in the amount; please fix the gap in order to validate the transaction.',
  REPORT: 'Postponed',
  NEGATIVE_OR_ZERO_NUMBER:
    'The ventilation contains a number that is negative or zero',
  VENTILATION_ZERO_NUMBER: 'The ventilation contains a null value',
  ATTACHMENT_SUCCESSFULLY_DELETED: 'Attachment successfully deleted',
  GET_STARTED: 'Get started',
  BUY_NOW: 'Buy now',
  PAYMENT_FAILED: 'Payment failed',
  PAYMENT_SUCCEEDED: 'Payment succeeded',
  GO_BACK_TO_PAYMENT: 'Go back to payment',
  PAYMENT_FAILED_DESCRIPTION:
    "We're sorry! Your payment could not be processed. Please check your payment details and try again. If the issue persists, feel free to contact our support team for assistance. Thank you for choosing Uvey!",
  DECLARATION_TEMPLATE_INITIATION: 'Declaration template initiation',
  INITIATE_YOUR_DECLARATION_TEMPLATE: 'Initiate your declaration template',

  WITHHOLDING_TAX: 'Withholding tax',
  PROFESSIONAL_TRAINING_TAX: 'Professional Training Tax',
  FOPROLOS: 'FOPROLOS',
  CONSUMER_RIGHTS: 'Consumer Rights',
  OTHER_TAXES: 'Other Taxes',
  INSURANCE_TAXES: 'Insurance taxes',
  STAMP_DUTY: 'Stamp Duty',
  LOCAL_TAXES: 'Local Taxes',
  MODIFY_YOUR_TEMPLATE: 'Edit template',
  LABELS: 'Labels',
  WITHHOLDING_BASE: 'Withholding base',
  RATES: 'Rates',
  WITHHOLDING_AMOUNT: 'Withholding amount',
  SALARIES_WAGES_PENSIONS_AND_ANNUITIES:
    'Salaries, wages, pensions and annuities',
  FEES_COMMISSIONS_BROKERAGE_FEES_RENTS_AND_REMUNERATION_FOR_NON_TRADING_ACTIVITIES:
    'Fees, commissions, brokerage fees, rents and remuneration for non-trading activities',
  SERVED_TO_RESIDENTS_AND_ESTABLISHMENTS:
    'Served to residents and establishments',
  INDIVIDUALS: 'Individuals',
  LEGAL_ENTITIES: 'Legal entities',
  SERVED_TO_NON_DOMICILED_AND_NON_ESTABLISHED_CUSTOMERS_WITH_THE_EXCEPTION_OF_RENTS:
    'Served to non-domiciled and non-established customers, with the exception of rents',
  FEES_PAID_TO_LEGAL_ENTITIES_AND_INDIVIDUALS_TAXED_UNDER_THE_ACTUAL_TAX_REGIME:
    'Fees paid to legal entities and individuals taxed under the actual tax regime',
  FEES_PAID_TO_EXPORT_ENGINEERING_CONSULTANTS:
    'Fees paid to export engineering consultants',
  HOTEL_RENTS: 'Hotel rents',
  OTHER_INCOME_PAID_TO_NON_DOMICILED_OR_NON_ESTABLISHED_INDIVIDUALS:
    'Other income paid to non-domiciled or non-established individuals',
  INTEREST_ON_DEPOSITS_IN_PRIVATE_SAVINGS_ACCOUNTS_AT_BANKS_AND_THE_NATIONAL_SAVINGS_BANK_OF_TUNISIA:
    'Interest on deposits in private savings accounts at banks and the National Savings Bank of Tunisia',
  INCOME_FROM_OTHER_MOVABLE_CAPITAL: 'Income from other movable capital',
  SERVED_TO_RESIDENTS: 'served to residents',
  SERVED_TO_NON_RESIDENTS: 'served to non-residents',
  INCOME_FROM_SECURITIES_ACCRUING_TO_NON_DOMICILED_NON_ESTABLISHED_INDIVIDUALS_WITH_THE_EXCEPTION_OF_SHARES_AND_CAPITAL_GAINS:
    'Income from securities accruing to non-domiciled, non-established individuals, with the exception of shares and capital gains from the sale of securities.',
  ATTENDANCE_FEES: 'Attendance fees',
  REMUNERATION_PAID_TO_EMPLOYEES_AND_SELF_EMPLOYED_PERSONS_FOR_OCCASIONAL_OR_INCIDENTAL_WORK_OUTSIDE_THEIR_MAIN_ACTIVITIES:
    'Remuneration paid to employees and self-employed persons for occasional or incidental work outside their main activities',
  INTEREST_ON_LOANS_PAID_TO_BANKING_INSTITUTIONS_NOT_ESTABLISHED_IN_TUNISIA:
    'Interest on loans paid to banking institutions not established in Tunisia',
  SALE_PRICE_DECLARED_IN_THE_DEED:
    'Sale price declared in the deed (capital gain)',
  FOR_RESIDENTS: 'For residents',
  FOR_NON_RESIDENTS: 'For non-residents',
  WITHHOLDING_TAX_ON_CONTRACTS_CONCLUDED_WITH_RESIDENTS_AND_ESTABLISHED:
    'Withholding tax on contracts concluded with residents and established non-residents for amounts greater than or equal to D5,000 and amounts paid by public establishments and companies greater than or equal to D1,000 including tax',
  WITHHOLDING_OF_VAT:
    'Withholding of VAT at source on amounts equal to or greater than D1000 inclusive of tax paid by public establishments and companies',
  WITHHOLDING_OF_VAT_ON_TRANSACTIONS_WITH_PERSONS_NOT_ESTABLISHED_IN_TUNISIA:
    'Withholding of VAT on transactions with persons not established in Tunisia',
  FEES_PAID_TO_NON_RESIDENT_PERSONS_WHO_CARRY_OUT_CONSTRUCTION_WORK:
    'Fees paid to non-resident persons who carry out construction work or assembly operations or related inspection services or other services and work for a period of less than 6 months.',
  MANUFACTURING_INDUSTRY: 'Manufacturing industry',
  OTHER_ACTIVITIES: 'Other activities',
  DEDUCTION_FROM_ADVANCE: 'Deduction from advance',
  DEDUCTION_OF_EXCESS_ADVANCE_NOT_DEDUCTED:
    'Deduction of excess advance not deducted',
  DEDUCTION_OF_REBATES_THAT_COULD_NOT_BE_DEDUCTED:
    'Deduction of rebates that could not be deducted',
  DEDUCTIBLE_TAX: 'Deductible tax',
  TAX_BASE: 'Tax base',
  TAX_DUE: 'Tax due',
  CONTRIBUTION_BASE: 'Contribution base',
  CONTRIBUTION_DUE: 'Contribution due',
  SALES_NET_OF_VAT_AND_DC: 'Sales net of VAT and DC',
  PURCHASING: 'Purchasing',
  LOCAL_PURCHASES: 'Local purchases',
  IMPORTED_PURCHASES: 'Imported purchases',
  SALES_SUBJECT_TO_VAT_NET_OF_VAT: 'Sales subject to VAT, net of VAT',
  PURCHASES_SUBJECT_TO_VAT_NET_OF_VAT_AND_GIVING_RIGHT_TO_DEDUCTION:
    'Purchases subject to VAT, net of VAT and giving right to deduction',
  PURCHASE_OF_FIXED_ASSETS: 'Purchase of fixed assets',
  PURCHASE_OF_EQUIPMENT: 'Purchase of equipment',
  LOCAL: 'Local',
  IMPORTED: 'Imported',
  OTHER_PURCHASES: 'Other purchases',
  OTHER_DEDUCTIONS: 'Other deductions',
  VAT_AND_THE_AMOUNT_OF_WITHHOLDING_TAX:
    'VAT due on amounts that are ≥ 1000 D including VAT and the amount of withholding tax.',
  VAT_DUE_ON_TRANSACTIONS_WITH_PERSONS:
    'VAT due on transactions with persons having no establishment in Tunisia',
  FLAT_RATE_VAT_ON_MEANS_OF_TRANSPORT: 'Flat-rate VAT on means of transport ',
  ADJUSTMENTS: 'Adjustments',
  ADDITIONAL_DEDUCTION: 'Additional deduction',
  TERMINATION_AND_CANCELLATION_TRANSACTIONS:
    'Termination and cancellation transactions ',
  OTHER_OPERATIONS: 'Other operations',
  PAYBACK: 'Payback',
  TAX: 'Tax',
  WITHHOLDING: 'Withholding',
  LEVIES_FOR_THE_INDUSTRIAL_COMPETITIVENESS_DEVELOPMENT_FUND_COMPETITIVENESS_IN_THE_INDUSTRIAL_SECTOR:
    'Levies for the industrial competitiveness development fund competitiveness in the industrial sector',
  BUSINESS_TAX: 'Business tax',
  TAX_ON_CANNED_FOOD: 'Tax on canned food',
  COMPETITIVENESS_IN_THE_AGRICULTURE_AND_FISHERIES_SECTOR:
    'Levies in favor of the fund for the development of competitiveness in the agriculture and fisheries sector',
  TAX_ON_FISHING_PRODUCTS: 'Tax on fishing products',
  TAX_ON_CORN_AND_SOYBEAN_SALES: 'Tax on corn and soybean sales',
  TAX_ON_FRUIT_AND_VEGETABLES: 'Tax on fruit and vegetables',
  MEAT_TAX: 'Meat tax',
  TAXES_FOR_THE_TOURISM_COMPETITIVENESS_DEVELOPMENT_FUND:
    'Taxes for the Tourism Competitiveness Development Fund',
  FEES_PAID_TO_THE_TELECOMMUNICATIONS_AND_INFORMATION:
    'Fees paid to the Telecommunications and Information and Communication Technologies Development Fund',
  TAX_FOR_THE_NATIONAL_EMPLOYMENT_FUND: 'Tax for the national employment fund',
  TAX_ON_LOCAL_SALES_OF_COFFEE_AND_TEA: 'Tax on local sales of coffee and tea',
  CEMENT_COMPENSATION_TAX: 'Cement compensation tax',
  CEMENT_SALES_TAX: 'Cement sales tax',
  TAX_ON_TOMATOES_FOR_PROCESSING: 'Tax on tomatoes for processing',
  CONTRIBUTION_FROM_TOMATO_GROWERS: 'Contribution from tomato growers',
  CONTRIBUTION_FROM_PROCESSING_PLANT_OPERATORS:
    'Contribution from processing plant operators',
  ENVIRONMENTAL_PROTECTION_TAX: 'Environmental protection tax',
  LEVY_FOR_THE_NATIONAL_ENERGY_MANAGEMENT_FUND:
    'Levy for the national energy management fund',
  TAX_ON_AIR_CONDITIONING_EQUIPMENT: 'Tax on air-conditioning equipment',
  TAX_ON_LAMPS_AND_TUBES_WITH_THE_EXCEPTION_OF_ENERGY_SAVING_ONES_FOR_MOTOR_VEHICLES_OR_MOTORCYCLES:
    'Tax on lamps and tubes, with the exception of energy-saving ones for motor vehicles or motorcycles',
  RIGHTS_ON_GAMES_PLAYED_DIRECTLY_BY_TELEPHONE_OR_THROUGH_SHORT_MESSAGES_OR_THE_VOICE_SERVER:
    'Rights on games played directly by telephone or through short messages or the voice server',
  INSURANCE_CATEGORY: 'Insurance category',
  TAXABLE_WRITTEN_PREMIUMS: 'Taxable written premiums',
  COINSURANCE_PREMIUM_WHEN_THE_INSURER_IS_NOT_THE_LEADING_INSURER:
    'Coinsurance premium when the insurer is not the leading insurer',
  TAXABLE_COINSURANCE_PREMIUMS_WHEN_THE_INSURER_IS_THE_LEAD_INSURER:
    'Taxable coinsurance premiums when the insurer is the lead insurer',
  PREMIUMS_CANCELLED_OR_RETURNED: 'Premiums cancelled or returned',
  REMAINDER_OF_CANCELLED_OR_REFUNDED_PREMIUMS:
    'Remainder of cancelled or refunded premiums',
  RISKS_OF_SEA_OR_AIR_NAVIGATION: 'Risks of sea or air navigation',
  OTHER_RISKS: 'Other risks',
  PRINCIPAL: 'Principal',
  CONTRIBUTIONS_TO_THE_FONDS_DE_GARANTIE_DES_VICTIMES:
    'Contribution of insurers to the benefit of the guarantee fund for victims of traffic accidents',
  LEVIES_FOR_THE_CIVIL_PROTECTION_AND_ROAD_SAFETY_FUND:
    'Levies for the civil protection and road safety fund and road safety fund',
  CONTRIBUTION_FROM_INSURERS: 'Contribution from insurers',
  CONTRIBUTION_FROM_INSURANCE_COMPANIES:
    'Contribution from insurance companies ',
  MOTOR_VEHICLE_ACCIDENT_INSURANCE: 'Motor vehicle accident insurance',
  OTHER_INSURANCE_PREMIUMS_EXCLUDING_LIFE_INSURANCE_AND_CAPITALIZATION_PREMIUMS:
    'Other insurance premiums, excluding life insurance and capitalization premiums',
  INSURANCE_COMPANY_CONTRIBUTIONS_TO_THE_POLICYHOLDERS_FUND:
    'Contribution of insurance companies to the benefit of the policyholder’s fund',
  CONTRIBUTION_TO_THE_TRAFFIC_ACCIDENT_PREVENTION_FUND:
    'Contribution to the traffic accident prevention fund',
  POLICYHOLDER_CONTRIBUTIONS: 'Policyholder contributions',
  TYPE_OF_INSURANCE: 'Type of insurance',
  WRITTEN_PREMIUMS_NET_OF_CANCELLATIONS_AND_REBATES:
    'Written premiums net of cancellations and rebates',
  REINSURANCE_CONTRACTS: 'Reinsurance contracts (premiums accepted)',
  INSURANCE_CONTRACTS_FOR_AGRICULTURAL_AND_FISHING_RISKS:
    'Insurance contracts for agricultural and fishing risks',
  EXPORT_RISK_INSURANCE_CONTRACTS_AND_EXPORT_CREDIT_INSURANCE_CONTRACTS:
    'Export risk insurance contracts and export credit insurance contracts',
  COMPULSORY_INSURANCE_CONTRACTS_FOR_RESIDENTIAL_CONSTRUCTION_UNDER_CURRENT_LEGISLATION:
    'Compulsory insurance contracts for residential construction under current legislation',
  LIFE_INSURANCE_CONTRACTS_CAPITALIZATION_CONTRACTS_AND_ANNUITY_CONTRACTS:
    'Life insurance contracts, capitalization contracts and annuity contracts',
  INSURANCE_CONTRACTS_FOR_RISKS_LOCATED_OUTSIDE_TUNISIA:
    'Insurance contracts for risks located outside Tunisia',
  OTHER_EXEMPTIONS: 'Other exemptions',
  COMPANY_NO: 'Company no.',
  TYPE_OF_PART_SUBJECT_TO_TAX: 'Type of part subject to tax',
  NUMBER_OF_PARTS: 'Number of parts',
  GROSS_SALES: 'Gross sales',
  GROSS_LOCAL_SALES: 'Gross local sales',
  IR_OR_IS_DUE: 'IR or IS due',
  HOTEL_TAX: 'Hotel tax',
  LIQUOR_LICENSE_FEE_FOR_THE_YEAR: 'Liquor license fee for the year',
  LOCAL_AUTHORITY: 'Local authority',
  CATEGORY: 'Category',
  GRAND_TOTAL: 'Grand total',
  NUMBER_OF_ESTABLISHMENTS: 'Number of establishments',
  OTHER_INSURANCE_TAXES: 'Other insurance taxes',
  EXEMPTIONS: 'Exemptions',
  ADD_YOUR_TAX_CALCULATOR_TEMPLATE: 'Add your tax calculator template',
  ADD_YOUR_TEMPLATE: 'Add your template',
  MONTHLY_DECLARATION: 'Monthly declaration',
  LOCAL_CONSUMPTION_TAX: 'Local consumption tax',
  LICENSE_FEE: 'License fee',
  SHARE: 'Share',
  REFRESH: 'Refresh',
  FINANCIAL_STATEMENT: 'Financial statement',
  BOOK_ENTRIES: 'Book Entries',
  ADD_IMPORT_ACCOUNTING_PLAN: 'Add / import accounting plan',
  SEEMS_LIKE_YOU_DIDNT_COMPLETE_YOUR_CONFIG_YET:
    "Seems like you didn't complete your config yet",
  COMPLETE_YOUR_CONFIG: 'Complete your config',
  VALIDATE_CONFIG: 'Validate config',
  VALIDATE_CONFIG_MESSAGE:
    'Please make sure you filled all the config inputs before validating',
  REGISTER: 'Register',
  VIEW: 'View',
  READINGS_HISTORY: 'Readings history',
  VIEW_AND_VALIDATE_YOUR_BANKING_TRANSACTIONS:
    'View and validate your banking transactions',
  MANAGE_YOUR_BANK_ACCOUNTS: 'Manage your bank accounts',
  SELECT_ALL: 'Select all',
  ACCOUNT: 'Account',
  MANAGE_YOUR_MONTHLY_BANK_STATEMENTS: 'Manage your monthly bank statements',
  NO_EXPORTED_FILES: ' No exported files',
  LIST_OF_REQUESTED_FILES: 'List of requested files',
  ACTIVATED: 'Activated',
  YEAR: 'Year',
  YOU_STILL_HAVE_AVAILABLE: 'You still have available',
  // ==============================
  // Navbar
  // ==============================
  dashboard: 'Dashboard',
  performances: 'Performances',
  transactions: 'Transactions',
  billing: 'Billing',
  quotes: 'Quotes',
  'delivery-notes': 'Delivery notes',
  client: 'Client',
  product: 'Products',
  suppliers: 'Suppliers',
  documents: 'Documents',
  chats: 'Chats',
  declarations: 'Declarations',
  withholding: 'Withholding tax',
  'vocational-training-tax': 'Professional Training Tax',
  foprolos: 'FOPROLOS',
  'consumer-rights': 'Consumer Rights',
  'added-value-tax': 'Added value tax',
  'other-taxes': 'Other taxes',
  'insurance-taxes': 'Insurance taxes',
  'stamp-duty': 'Stamp duty',
  'local-taxes': 'Local Taxes',
  accounting: 'Accounting',
  'accounting-plan': 'Accounting plan',
  'book-entries': 'Book Entries',
  ledger: 'Ledger',
  'category-balance': 'Category Balance',
  'financial-statement': 'Financial statement',
  'settings-accountant': 'Settings accountant',
  profile: 'Profile',
  enterprise: 'Company',
  team: 'Team',
  subscription: 'Subscription',
  password: 'Password',
  'export-data': 'Data export',
  'ocr-management': 'Page management',
  'add-pages': 'Page offers',
  template: 'Template',
  CHOOSE_YOUR_TEMPLATE_ELEMENTS: 'Choose your template elements',
  NOT_AVAILABLE: 'Not available',
  BANK: 'Bank',
  TODAY: 'Today',
  ANNUAL: 'Annual',
  SEMESTER: 'Semester',
  QUARTER: 'Quarter',
  statements: 'Statements',
  'add-bank-accounts': 'Add bank accounts',
  MANAGE_BANK_ACCOUNTS: 'Manage bank accounts',
  FILE_SIZE_EXCEEDED: 'exceeds the maximum size of 1 MB.',
  THE_FILE: 'File',
  FILTER_BY_AMOUNT: 'Filter by amount',
  WILL_CREATE_MANUAL_TRANSACTION:
    'Choosing one of the CCA or Cash will create a new manual transaction.',
  DECLARATION_DATE: 'Declaration date',
  BY: 'Created by',
  YOU_CAN_NAVIGATE_USING_THE_ARROW_BUTTONS:
    'You can navigate using the arrow buttons',
  DISPLAY_ALL: 'Display all',
  ADD_STATEMENT: 'Add statement',
  IMPORT_TRANSACTION: 'Import transaction',
  ADVANCED_SEARCH: 'Advanced search',
  PLEASE_SELECT_A_SUPPLIER: 'Please select a supplier',
  CLIENT_ADDRESS: 'Client address',
  REQUESTOR: 'Requestor',
  FULL_NAME: 'Full name',
  MAX_52_CHARACTER: 'Max 52 character',
  FOR_BEST_RESULTS_CHOOSE_AN_IMAGE_RESOLUTION:
    'For best results, choose an image resolution of 800x100 px',
  ADDED_MANUALLY: 'Added manually',
  LAST_UPDATE: 'Last update',
  EMAIL_CONTROLLER: 'Please enter a valid e-mail address',
  LIST_OF_RECEIPTS: 'List of receipts',
  LATEST_BALANCE: 'Latest balance',
  PRICE_LOWEST: 'Price lowest',
  PRICE_HIGHEST: 'Price highest',
  LATEST: 'latest',
  OLDEST: 'Oldest',
  SORT_BY_NUMBER_ASC: 'Number (ascending)',
  SORT_BY_NUMBER_DESC: 'Number (descending)',
  à: 'at',
  EMAIL_CONFIGURATION: 'E-mail configuration',
  BILLINGS_CONFIGURATION: 'Billings configuration',
  REPLY_TO: 'Reply-to',
  CC: 'Copy addresses',
  REPLY_SPEC:
    'Enter the email address where you want to receive replies from customers when they reply to an email sent via Uvey.',
  CC_SPEC:
    'Add one or more email addresses to copy emails sent to your customers.',
  NEW_EMAIL: 'New Email',
  PLEASE_SEPARATE_EACH_EMAIL:
    'Please separate each email address with a semicolon (;)',
  EMAIL_INFOS_UPDATED_SUCCESSFULLY: 'The data has been saved',
  USED: 'used',
  'bill-by-mail': 'E-mail configuration',
  BACK_TO_BILLINGS: 'Back to billings',
  BACK_TO_QUOTES: 'Back to quotes',
  BACK_TO_DELIVERY_NOTE: 'Back to delivery note',
  UPLOADED_AT: 'Uploaded on',
  PAYMENT_LINK: 'Payment link',
  REQUIRED_FIELD: 'Required field',
  HOW_TO_GET_IT: 'How to get it',
  ADD_FEES_TO_THE_AMOUNT: 'Adds Konnect fees to payers amount',
  ACCEPT_CREDIT_CARD_PAYMENTS: 'Accept credit card payments',
  PAYMENT_LINK_PREF:
    'This section lets you configure the payment method you prefer based on your needs; you still can manage some preferences on this page.',
  EMAIL_CONFIGURATION_DESC:
    'This section lets you configure the reply-to address and copy recipients for sales invoice emails sent via Uvey.',
  TEMPLATE_EMAIL: 'Email Template',
  VENTILATION_AMOUNT_GREATER_THAN_RECEIPT_UNASSOCIATED_AMOUNT:
    'The ventilation amount is greater than the unassociated amount of the receipt.',
  CREATE_A_PAYMENT_LINK_FOR_THIS_BILL: 'Create a payment link for this bill.',
  SELECT_PAYMENT_METHOD: 'Select payment method',
  SEEMS_LIKE_YOU_DONT_HAVE_PAYMENT_METHOD:
    "Seems like you haven't set up your payment link configuration yet. want to set it up now?",
  ADD_PAYMENT_METHOD: 'Add payment method',
  THE_PAYMENT_LINK_CAN_BE_USED_LATER:
    'The payment link can be used later to pay for this bill, it can be sent to the client via email',
  SAVE_SUCCESSFUL: 'Saved successfully',
  CREATE_A_NEW_VENTILATION: 'Create a new ventilation',
  ADD_WITHHOLDING_TAX: 'Add withholding tax',
  ADD_EXCHANGE_LOSS: 'Exchange loss',
  ADD_EXCHANGE_GAIN: 'Exchange gain',
};

export default EN_TRANSLATIONS;
