import { useMutation } from '@tanstack/react-query';
import * as yup from 'yup';

import { useAppTranslation } from '@/hooks';
import type { BankAccountsFormType } from '@/lib/forms';
import { BanksService, CompanyService } from '@/services';

export const useCreateAccount = () =>
  useMutation({
    mutationFn: (data: FormData) => {
      return CompanyService.createCompany(data);
    },
  });

export const useAddBankAccounts = () =>
  useMutation({
    mutationFn: (data: BankAccountsFormType) => {
      return BanksService.addBankAccount(data);
    },
  });

export const useBankAccountsFormSchema = () => {
  const { t } = useAppTranslation();

  return yup.object().shape({
    rib: yup
      .string()
      .required(t('Please type your RIB.'))
      .length(20, t('The RIB must contain 20 digits.'))
      .matches(/^[0-9]\d*$/, t('Please verify your RIB.')),
    currency: yup.string().required(),
    label: yup.string().required(),
  });
};

export const bankAccountsFormSchema = yup.object().shape({
  rib: yup
    .string()
    .length(20)
    .matches(/^[0-9]\d*$/)
    .required(),
  currency: yup.string().required(),
  label: yup.string().required(),
});

export const editBankAccountsFormSchema = yup.object().shape({
  label: yup.string().required(),
  addedAt: yup.string().optional(),
  closedAt: yup.string().optional(),
});

export const addCompanyFormSchema = yup.object().shape({
  credit: yup.string().required(),
  reason: yup.string().required(),
});
