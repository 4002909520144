import moment from 'moment';

import type {
  CsvTransaction,
  ManualTransaction,
} from '@/lib/forms/transactions-forms';
import type { ExportDataType } from '@/pages/dashboard/Accounting/ExportData/ExportData';
import type { StatusEnum } from '@/pages/dashboard/Receipts/enums';
import { InvoiceType } from '@/pages/dashboard/Receipts/enums';
import operationsAPI from '@/services/apis/operationsAPI';

import type {
  BookEntriesExport,
  BookEntry,
  Filters,
  PaymentAction,
  Receipt,
} from './models';

export default class OperationsService {
  static async getTransactions(
    filters: {
      status: string;
      currency: string;
      searchQuery: string;
      bank_account_id: string;
      pageSize: number;
      pageNumber: number;
      fiscalYear: number;
      startDate: string;
      endDate: string;
      totalAmount: number[] | null;
    },
    signal?: AbortSignal
  ) {
    const queryParameters: string[] = [];
    if (filters) {
      if (filters.fiscalYear) {
        queryParameters.push(`fiscal_year=${filters.fiscalYear}`);
      }
      if (filters.status) {
        queryParameters.push(`status=${filters.status}`);
      }
      if (filters.currency) {
        queryParameters.push(`currency_id=${filters.currency}`);
      }
      if (filters.searchQuery) {
        queryParameters.push(`search_query=${filters.searchQuery}`);
      }
      if (filters.bank_account_id) {
        queryParameters.push(`bank_account_id=${filters.bank_account_id}`);
      }
      if (filters.pageSize) {
        queryParameters.push(`page_size=${filters.pageSize}`);
      }
      if (filters.pageNumber) {
        queryParameters.push(`page_number=${filters.pageNumber}`);
      }
      if (filters.startDate) {
        queryParameters.push(`start_date=${filters.startDate}`);
      }
      if (filters.endDate) {
        queryParameters.push(`end_date=${filters.endDate}`);
      }
      if (filters.totalAmount) {
        queryParameters.push(`minAmount=${filters.totalAmount[0]}`);
        queryParameters.push(`maxAmount=${filters.totalAmount[1]}`);
      }
    }

    return operationsAPI.get(`/v1/transactions?${queryParameters.join('&')}`, {
      signal,
    });
  }

  static async createTransaction(
    data: ManualTransaction,
    signal?: AbortSignal
  ) {
    const formData = new FormData();
    formData.append('paymentChannel', data.paymentChannel);
    formData.append('shareholderUserId', data.shareholderUserId);
    formData.append('flowDirection', data.flowDirection);
    formData.append('annotation', data.description);
    formData.append(
      'transactionDate',
      moment(data.transactionDate).format('YYYY/MM/DD')
    );
    formData.append('description', data.annotation);
    formData.append('amount', String(data.amount));
    formData.append('currencyId', data.currencyId);
    formData.append('categoryCode', data.categoryCode);
    return operationsAPI.post(`/v1/transactions`, formData, {
      signal,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static async validateTransactionByOwner(id: string, signal?: AbortSignal) {
    return operationsAPI.patch(`/v1/transactions/${id}/validate/owner`, {
      signal,
    });
  }
  static async validateTransactionByAccountant(
    id: string,
    signal?: AbortSignal
  ) {
    return operationsAPI.patch(`/v1/transactions/${id}/validate/accountant`, {
      signal,
    });
  }
  static async invalidateTransaction(id: string, signal?: AbortSignal) {
    return operationsAPI.patch(`/v1/transactions/${id}/invalidate`, {
      signal,
    });
  }

  static async getTransactionsExport(
    bankStatementId: string,
    signal?: AbortSignal
  ) {
    return operationsAPI.get(
      `/v1/transactions/export?bank_statement_id=${bankStatementId}`,
      {
        signal,
      }
    );
  }

  static async getFiscalYears(signal?: AbortSignal) {
    return operationsAPI.get(`/v1/fiscal-years`, {
      signal,
    });
  }

  static async createFiscalYear(fiscalYear: number, signal?: AbortSignal) {
    return operationsAPI.post(
      `/v1/fiscal-years/create/${fiscalYear}`,
      {},
      {
        signal,
      }
    );
  }

  static async lockFiscalYear(fiscalYear: number, signal?: AbortSignal) {
    return operationsAPI.put(
      `/v1/fiscal-years/${fiscalYear}/lock`,
      {},
      {
        signal,
      }
    );
  }
  static async unlockFiscalYear(fiscalYear: number, signal?: AbortSignal) {
    return operationsAPI.put(
      `/v1/fiscal-years/${fiscalYear}/unlock`,
      {},
      {
        signal,
      }
    );
  }

  static async getReceiptsWithPagination(
    fiscalYear: number | null | undefined,
    pageNumber: number | undefined,
    pageSize: number | undefined,
    statusFilter: StatusEnum | null,
    invoiceType: InvoiceType | null,
    filters: Filters | null,
    signal?: AbortSignal
  ) {
    let url = '/v1/receipts';
    const queryParameters: string[] = [];
    if (fiscalYear) {
      queryParameters.push(`fiscalYear=${fiscalYear}`);
    }
    if (pageNumber) {
      queryParameters.push(`pageNumber=${pageNumber}`);
    }
    if (pageSize) {
      queryParameters.push(`pageSize=${pageSize}`);
    }
    if (statusFilter) {
      queryParameters.push(`status=${statusFilter}`);
    }
    if (invoiceType && invoiceType !== InvoiceType.ALL) {
      queryParameters.push(`invoiceType=${invoiceType}`);
    }
    if (filters) {
      if (filters.ids) {
        queryParameters.push(`ids=${filters.ids}`);
      }
      if (filters.category) {
        queryParameters.push(`category=${filters.category}`);
      }
      if (filters.totalAmount) {
        queryParameters.push(`minAmount=${filters.totalAmount[0]}`);
        queryParameters.push(`maxAmount=${filters.totalAmount[1]}`);
      }
      if (filters.vatMin) {
        queryParameters.push(`vatMin=${Number(filters.vatMin)}`);
      }
      if (filters.vatMax) {
        queryParameters.push(`vatMax=${Number(filters.vatMax)}`);
      }
      if (filters.searchQuery) {
        queryParameters.push(`searchQuery=${filters.searchQuery}`);
      }
      if (filters.startDate) {
        queryParameters.push(`startDate=${filters.startDate}`);
      }
      if (filters.endDate) {
        queryParameters.push(`endDate=${filters.endDate}`);
      }
    }
    if (queryParameters.length > 0) {
      url += '?' + queryParameters.join('&');
    }
    return operationsAPI.get(url, {
      signal,
    });
  }

  static async createReceipt(data: FormData, signal?: AbortSignal) {
    return operationsAPI.post(`/v1/receipts`, data, {
      signal,

      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  static async createReceiptFromDocs(
    fiscalYear: number,
    filePath: string,
    signal?: AbortSignal
  ) {
    const data = {
      fiscalYear,
      filePath,
    };
    return operationsAPI.post(`/v1/receipts/docs`, data, {
      signal,
    });
  }
  static async updateReceipt(
    data: Receipt,
    withVentilation: boolean = false, // Default value set to false
    isTriggeredManually: boolean = false, // Default value set to false
    validation: boolean = false, // Default value set to false
    signal?: AbortSignal
  ) {
    return operationsAPI.put(
      `/v1/receipts?withVentilation=${withVentilation}&isTriggeredManually=${isTriggeredManually}&validation=${validation}`,
      data,
      {
        signal,
      }
    );
  }

  static getReceipt(id: string, signal?: AbortSignal) {
    return operationsAPI.get(`v1/receipts/${id}`, {
      signal,
    });
  }

  static uploadReceiptAttachments(
    receiptId: string,
    data: FormData,
    signal?: AbortSignal
  ) {
    return operationsAPI.put(`v1/receipts/${receiptId}`, data, {
      signal,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static uploadTransactionAttachments(
    transactionId: string,
    data: FormData,
    signal?: AbortSignal
  ) {
    return operationsAPI.put(`v1/transactions/${transactionId}`, data, {
      signal,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static getTransactionsAttachments(
    transactionId: string,
    signal?: AbortSignal
  ) {
    return operationsAPI.get(`v1/transactions/${transactionId}/attached-file`, {
      signal,
    });
  }

  static getReceiptAttachments(receiptId: string, signal?: AbortSignal) {
    return operationsAPI.get(`v1/receipts/${receiptId}/attached-file`, {
      signal,
    });
  }

  static splitMultiReceiptDocument(data: FormData, signal?: AbortSignal) {
    return operationsAPI.post(`v1/receipts/split`, data, {
      signal,

      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static getTransaction(id: string, signal?: AbortSignal) {
    return operationsAPI.get(`/v1/transactions/${id}`, {
      signal,
    });
  }
  static getDuplicatedReceipts(id: string, signal?: AbortSignal) {
    return operationsAPI.get(`/v1/receipts/duplications/${id}`, {
      signal,
    });
  }

  static removeDuplication(
    baseID: string,
    duplicateID: string,
    signal?: AbortSignal
  ) {
    return operationsAPI.patch(
      `/v1/receipts/duplications/${baseID}/${duplicateID}`,
      {
        signal,
      }
    );
  }
  static validateDuplications(baseID: string, signal?: AbortSignal) {
    return operationsAPI.patch(`/v1/receipts/duplications/${baseID}`, {
      signal,
    });
  }

  static async createReceiptCsv(
    data: CsvTransaction[],
    dryRun: boolean,
    signal?: AbortSignal
  ) {
    return operationsAPI.post(`/v1/transactions/csv?dry_run=${dryRun}`, data, {
      signal,
    });
  }

  static updateTransaction(
    id: string,
    data: {
      categoryCode?: string;
      transactionVentilations?: any;
      receiptsIds?: Array<string>;
    },
    signal?: AbortSignal
  ) {
    return operationsAPI.patch(`v1/transactions/${id}`, data, {
      signal,
    });
  }

  static deleteTransaction(id: string, signal?: AbortSignal) {
    return operationsAPI.delete(`/v1/transactions/${id}`, {
      signal,
    });
  }

  static assignReceipts(
    id: string,
    data: { receiptsIds?: Array<string> },
    signal?: AbortSignal
  ) {
    return operationsAPI.patch(`v1/transactions/${id}/assign`, data, {
      signal,
    });
  }

  static unassignReceipts(
    id: string,
    data: { receiptsIds?: Array<string> },
    signal?: AbortSignal
  ) {
    return operationsAPI.patch(`v1/transactions/${id}/unassign`, data, {
      signal,
    });
  }

  static exportData(data?: ExportDataType, signal?: AbortSignal) {
    const queryParameters: string[] = [];

    if (data?.start_date) {
      queryParameters.push(`start_date=${data?.start_date}`);
    }

    if (data?.end_date) {
      queryParameters.push(`end_date=${data?.end_date}`);
    }

    if (data?.export_format) {
      queryParameters.push(`export_format=${data?.export_format}`);
    }

    return operationsAPI.get(`v1/book-entries?${queryParameters.join('&')}`, {
      signal,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    });
  }
  static getLedgerData(
    code: string,
    filters?: {
      startDate?: string;
      endDate?: string;
      label?: string;
      journals?: string[];
    },
    signal?: AbortSignal
  ) {
    const queryParameters: string[] = [];

    if (filters?.startDate) {
      queryParameters.push(`start_date=${filters?.startDate}`);
    }

    if (filters?.endDate) {
      queryParameters.push(`end_date=${filters?.endDate}`);
    }

    if (filters?.label) {
      queryParameters.push(`label=${filters?.label}`);
    }

    if (filters?.journals) {
      queryParameters.push(`journals=${filters?.journals}`);
    }

    return operationsAPI.get(
      `v1/book-entries/ledger/${code}?${queryParameters.join('&')}`,
      {
        signal,
      }
    );
  }

  static getBookEntriesManually(
    data: {
      startDate?: string;
      endDate?: string;
      categories?: string[];
      label?: string;
      journals?: string[];
    },
    signal?: AbortSignal
  ) {
    const queryParameters: string[] = [];

    if (data?.startDate) {
      queryParameters.push(`start_date=${data?.startDate}`);
    }

    if (data?.endDate) {
      queryParameters.push(`end_date=${data?.endDate}`);
    }

    if (data?.categories) {
      queryParameters.push(`categories=${data?.categories}`);
    }

    if (data?.label) {
      queryParameters.push(`label=${data?.label}`);
    }

    if (data?.journals) {
      queryParameters.push(`journals=${data?.journals}`);
    }

    return operationsAPI.get(
      `v1/book-entries/manual?${queryParameters.join('&')}`,
      {
        signal,
      }
    );
  }
  static updateBookEntriesManually(data: BookEntry, signal?: AbortSignal) {
    return operationsAPI.patch(`v1/book-entries`, data, {
      signal,
    });
  }

  static createBookEntriesManually(data: BookEntry, signal?: AbortSignal) {
    return operationsAPI.post(`v1/book-entries`, data, {
      signal,
    });
  }

  static deleteBookEntriesManually(
    fiscalYear: number,
    journalId: string,
    pieceNumber: number,
    signal?: AbortSignal
  ) {
    return operationsAPI.delete(
      `v1/book-entries/${fiscalYear}/${journalId}/${pieceNumber}`,
      {
        signal,
      }
    );
  }

  static getKpiValidation(fiscalYear: number | null = 0, signal?: AbortSignal) {
    return operationsAPI.get(
      `v1/kpis/kpi-validation?fiscalYear=${fiscalYear}`,
      {
        signal,
      }
    );
  }
  static deleteReceipt(id: string, signal?: AbortSignal) {
    return operationsAPI.delete(`/v1/receipts/${id}`, {
      signal,
    });
  }

  static deleteReceiptsByIds(ids: string[], signal?: AbortSignal) {
    return operationsAPI.delete(`/v1/receipts/multiple-delete?ids=${ids}`, {
      signal,
    });
  }
  static async invalidateReceipt(id: string, signal?: AbortSignal) {
    return operationsAPI.patch(`/v1/receipts/${id}/invalidate`, {
      signal,
    });
  }

  static getClientKpis(
    fiscalYear: number,
    baseCode: number,
    signal?: AbortSignal
  ) {
    return operationsAPI.get(
      `v1/kpis/kpi-client-dashboard?fiscalYear=${fiscalYear}&baseCode=${baseCode}`,
      {
        signal,
      }
    );
  }

  static async getCategoryBalance(
    fiscalYear: number | null,
    startDate?: string,
    endDate?: string,
    signal?: AbortSignal
  ) {
    const params: { [key: string]: any } = {};

    if (fiscalYear !== null) {
      params.fiscal_year = fiscalYear;
    }
    if (startDate) {
      params.start_date = startDate.toString();
    }

    if (endDate) {
      params.end_date = endDate.toString();
    }

    return operationsAPI.get(`v1/book-entries/account-balance`, {
      params,
      signal,
    });
  }

  static async getBookEntriesExportHistory(signal?: AbortSignal) {
    const res = await operationsAPI.get(`v1/book-entries/exports`, {
      signal,
    });

    return res.data.bookEntriesExports as BookEntriesExport[];
  }

  static async downloadPreviousBookEntriesExport(
    id: string,
    signal?: AbortSignal
  ) {
    const res = await operationsAPI.get(`v1/book-entries/exports/${id}`, {
      signal,
    });

    return res.data;
  }

  static addPaymentActionToReceipt(
    data: PaymentAction,
    receiptId: String | undefined,
    signal?: AbortSignal
  ) {
    const requestBody = {
      paymentChannel: data.paymentChannel,
      paymentActionSource: data.paymentActionSource,
      amount: data.amount,
      paymentDate: data.paymentDate,
    };
    return operationsAPI.patch(
      `v1/receipts/${receiptId}/payment-action`,
      requestBody,
      {
        signal,
      }
    );
  }

  static deletePaymentActionToReceipt(
    data: PaymentAction[],
    transactionId: string | null | undefined,
    receiptId: string | undefined,
    signal?: AbortSignal
  ) {
    const requestBody = {
      remainingPaymentActions: data,
      transactionId: transactionId,
    };
    return operationsAPI.patch(
      `v1/receipts/${receiptId}/delete-payment-action`,
      requestBody,
      {
        signal,
      }
    );
  }

  static importBookEntries(file: File, signal?: AbortSignal) {
    const formData = new FormData();
    formData.append('file', file);

    return operationsAPI.post('v1/book-entries/import', formData, {
      signal,
    });
  }
}
