import type { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import { ChevronDown } from '@/assets/icons';
import { ButtonComponent } from '@/components/ui/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/Dropdown';
import { useAppSelector, useAppTranslation } from '@/hooks';
import { cls } from '@/utils/funcs';

interface TabsProps {
  selected: string;
}

const Tabs: FC<TabsProps> = ({ selected }) => {
  const navigate = useNavigate();
  const { t } = useAppTranslation();
  const connectedUser = useAppSelector((store) => store.global.connectedUser);

  const SECTIONS = [
    // { name: t('EXPORT_DATA'), href: 'export-data', permission: 'ALL' },
    {
      name: 'IMPORT_BOOKENTRIES',
      href: 'bookentries-import',
      permission: 'ACCOUNTANT',
    },
    { name: 'ACCOUNTING_PLAN', href: 'accounting-plan', permission: 'ALL' },
    // { name: 'FISCAL_YEARS', href: 'fiscal-year', permission: 'ALL' },
    // {
    //   name: 'IMPORT_ACCOUNTING_PLAN',
    //   href: 'accounting-plan-import',
    //   permission: 'ACCOUNTANT',
    // },
    { name: 'Book entries', href: 'book-entries', permission: 'ACCOUNTANT' },
    { name: 'Ledger', href: 'ledger', permission: 'ACCOUNTANT' },
    {
      name: 'CATEGORY_BALANCE',
      href: 'category-balance',
      permission: 'ACCOUNTANT',
    },
    {
      name: 'FINANCIAL_STATEMENT',
      href: 'financial-statement',
      permission: 'ACCOUNTANT',
    },
    {
      name: 'Settings',
      href: 'settings-accountant/fiscal-year',
      permission: 'ALL',
    },
  ] as const;

  return (
    <>
      <div className="md:flex hidden">
        {SECTIONS.map(
          ({ href, name, permission }) =>
            (permission == 'ALL' ||
              connectedUser.roles.includes(permission)) && (
              <div
                key={href}
                className={cls(
                  'font-medium text-sm px-4 py-3',
                  name === selected
                    ? 'font-semibold text-primary-default border-b-2 border-primary-default z-20'
                    : 'text-grayscale-text-caption cursor-pointer'
                )}
                onClick={() => navigate(`/dashboard/accounting/${href}`)}
              >
                {t(name)}
              </div>
            )
        )}
      </div>

      <hr className="h-0.5 bg-grayscale-light -translate-y-[2px] z-10 md:block hidden" />
      <div className="w-full md:hidden">
        <DropdownMenu>
          <DropdownMenuTrigger className="w-full">
            <ButtonComponent
              variant="outline"
              className="w-full flex justify-between items-center font-semibold"
            >
              {t(`${selected}`)}
              <ChevronDown width={16} height={16} strokeColor="#000" />
            </ButtonComponent>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="min-w-[var(--radix-popper-anchor-width)]">
            {SECTIONS.map(
              ({ href, name, permission }) =>
                (permission == 'ALL' ||
                  connectedUser.roles.includes(permission)) && (
                  <DropdownMenuItem
                    className="w-full"
                    onClick={() => navigate(`/dashboard/billing/${href}`)}
                  >
                    {t(name)}
                  </DropdownMenuItem>
                )
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </>
  );
};

export default Tabs;
