import { type FC, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { Navbar, Sidebar } from '@/components/layouts';
import { useAppSelector, useAppTranslation } from '@/hooks';
import { appToast } from '@/lib/ToastContainers';
import { DeclarationService } from '@/services';
import { setDeclarationFormData } from '@/store/actions/action-creators';

interface DeclarationsProps {}

const DeclarationsLayout: FC<DeclarationsProps> = () => {
  const { t } = useAppTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const fiscalYear = useAppSelector((store) => store.global.fiscalYear);
  const isMonthExist = useAppSelector((store) => store.global.isMonthExist);

  const getConfig = () => {
    DeclarationService.getDeclarationConfig(fiscalYear)
      .then(({ data }) => {
        dispatch(setDeclarationFormData(data.config || { fields: {} }));
      })
      .catch(() => {
        dispatch(setDeclarationFormData(null));
      });
  };

  const getDeclaration = (month: number) => {
    DeclarationService.getDeclaration(fiscalYear, month)
      .then(({ data }) => {
        dispatch(setDeclarationFormData(data || {}));
      })
      .catch(() => {
        appToast.error(
          t('Something went wrong please contact support for help.')
        );

        dispatch(setDeclarationFormData(null));
      });
  };

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 796) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };
    handleResize(); // Call on initial render
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    isMonthExist ? getDeclaration(isMonthExist) : getConfig();
  }, [isMonthExist, fiscalYear, location]);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto bg-white">
        {/* Navbar */}
        <Navbar />

        {/* Main section */}
        <main className="bg-white  h-[calc(100vh-90px)] min-w-0 min-h-0">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default DeclarationsLayout;
