import React, { useEffect, useState } from 'react';

import { Add, Delete, Edit, MoreHoriz, Receipt } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { ChevronDown, EditLine } from '@/assets/icons';
import { empty } from '@/assets/images/dashboard';
import {
  Badge,
  Button,
  ConfirmationModal,
  LoadingSpinner,
} from '@/components/global';
import { ButtonComponent } from '@/components/ui/Button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '@/components/ui/Dropdown';
import {
  useAppComponentVisible,
  useAppSelector,
  useAppTranslation,
} from '@/hooks';
import { appToast } from '@/lib/ToastContainers';
import { operationsErrors } from '@/lib/errors';
import type { Document, FiscalYear } from '@/services';
import BillingService from '@/services/BillingService';
import { formatBalance } from '@/utils/funcs';

import { Tabs } from '../common';

enum StatusEnum {
  ALL = 'ALL',
  INIT = 'INIT',
  ARCHIVED = 'ARCHIVED',
}

enum DeliveryNotesStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

enum DeleteDeliveryNoteStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

enum ArchiveDeliveryNoteStatus {
  INITIAL,
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}

const PAGE_SIZE = 20;

const DeliveryNotes = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();

  const [deleteDeliveryNoteStatus, setDeleteDeliveryNoteStatus] = useState(
    DeleteDeliveryNoteStatus.INITIAL
  );
  const [archiveDeliveryNoteStatus, setArchiveDeliveryNoteStatus] = useState(
    ArchiveDeliveryNoteStatus.INITIAL
  );
  const [statusFilter, setStatusFilter] = useState(StatusEnum.ALL);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);

  const [selectedId, setSelectedId] = useState<string>('');

  const [isDeleteDeliveryNoteModal, setIsDeleteDeliveryNoteModal] =
    useState<boolean>(false);
  const [isArchiveDeliveryNoteModal, setIsArchiveDeliveryNoteModal] =
    useState<boolean>(false);
  const [documentLatestNote, setDocumentLatestNote] = useState<string>('');

  const {
    ref: menuRef,
    isVisible: isMenuOpen,
    setIsVisible: setIsMenuOpen,
  } = useAppComponentVisible(false);

  const [deliveryNotes, setDeliveryNotes] = useState<Document[]>([]);
  const [deliveryNotesStatus, setDeliveryNotesStatus] = useState(
    DeliveryNotesStatus.INITIAL
  );

  const fiscalYear = useAppSelector((store) => store.global.fiscalYear);
  const [fiscalYearUsed, setFiscalYearUsed] = useState<FiscalYear>();
  const [position, setPosition] = useState('bottom');
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });

  const [filters, setFilters] = useState({
    fiscalYear: fiscalYear,
    page: 0,
    pageSize: PAGE_SIZE,
    status: StatusEnum.INIT.toString(),
  });

  const handleToggleMenu = (e: any, id: any) => {
    e.stopPropagation();
    setIsMenuOpen((prev) => !prev);

    const buttonRect = e.currentTarget.getBoundingClientRect();
    setDropdownPosition({
      top: buttonRect.bottom + window.scrollY,
      left: buttonRect.left + window.scrollX,
    });

    setSelectedId(id);
  };

  const setPageNumberFunction = (e: any, pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  useEffect(() => {
    const controller = new AbortController();

    setDeliveryNotesStatus(DeliveryNotesStatus.IN_PROGRESS);

    BillingService.getAllDeliveryNotes(filters, controller.signal)
      .then(({ data }) => {
        setDeliveryNotes(data.deliveryNotes);
        setTotalPages(data.totalNumberOfPages);
        setDeliveryNotesStatus(DeliveryNotesStatus.SUCCESS);
        setDocumentLatestNote(data.deliveryNotes[0]?.footerInfo ?? '');
      })
      .catch(() => {
        setDeliveryNotesStatus(DeliveryNotesStatus.ERROR);
      });

    return () => controller.abort();
  }, [filters.status]);

  const getDeliveryNotes = async () => {
    const controller = new AbortController();
    setDeliveryNotesStatus(DeliveryNotesStatus.IN_PROGRESS);

    BillingService.getAllDeliveryNotes(filters, controller.signal)
      .then(({ data }) => {
        setDeliveryNotes(data.deliveryNotes);
        setTotalPages(data.totalNumberOfPages);
        setDeliveryNotesStatus(DeliveryNotesStatus.SUCCESS);
        setDocumentLatestNote(data.deliveryNotes[0]?.footerInfo ?? '');
      })
      .catch(() => {
        setDeliveryNotesStatus(DeliveryNotesStatus.ERROR);
      });

    return () => controller.abort();
  };

  useEffect(() => {
    setFilters({
      ...filters,
      fiscalYear,
      page: pageNumber,
      pageSize: PAGE_SIZE,
    });
    const fiscalYears: FiscalYear[] = JSON.parse(
      localStorage.getItem('fiscalYears') ?? ''
    );
    if (fiscalYears && fiscalYear) {
      setFiscalYearUsed(
        fiscalYears.find((year) => year.fiscalYear === Number(fiscalYear))
      );
    }
  }, [fiscalYear, pageNumber, PAGE_SIZE]);

  useEffect(() => {
    const controller = new AbortController();

    setDeliveryNotesStatus(DeliveryNotesStatus.IN_PROGRESS);

    BillingService.getAllDeliveryNotes(filters, controller.signal)
      .then(({ data }) => {
        setDeliveryNotes(data.deliveryNotes);
        setDeliveryNotesStatus(DeliveryNotesStatus.SUCCESS);
        setDocumentLatestNote(data.deliveryNotes[0]?.footerInfo ?? '');
      })
      .catch(() => {
        setDeliveryNotesStatus(DeliveryNotesStatus.ERROR);
      });

    return () => controller.abort();
  }, [filters.fiscalYear, filters.page, filters.pageSize]);

  const update = () => {
    setStatusFilter(StatusEnum.INIT);
    setPageNumber(0);
    setTotalPages(1);
  };

  const deleteDeliveryNote = () => {
    setDeleteDeliveryNoteStatus(DeleteDeliveryNoteStatus.IN_PROGRESS);

    BillingService.deleteDeliveryNote(selectedId)
      .then(() => {
        getDeliveryNotes();
        setDeleteDeliveryNoteStatus(DeleteDeliveryNoteStatus.SUCCESS);
        handleDeleteDeliveryNoteModal();
        appToast.success(t('DELETE_MESSAGE_DELIVERY_NOTE'));
      })
      .catch((e) => {
        e.response.data.code === operationsErrors.FiscalYear.YEAR_IS_LOCKED
          ? appToast.error(t(operationsErrors.FiscalYear.YEAR_IS_LOCKED))
          : appToast.error('Something went wrong, please try again.');
        setDeleteDeliveryNoteStatus(DeleteDeliveryNoteStatus.ERROR);
      });
  };

  const archiveDeliveryNote = () => {
    setArchiveDeliveryNoteStatus(ArchiveDeliveryNoteStatus.IN_PROGRESS);

    BillingService.archiveDeliveryNote(selectedId)
      .then(() => {
        getDeliveryNotes();
        setArchiveDeliveryNoteStatus(ArchiveDeliveryNoteStatus.SUCCESS);
        handleArchiveDeliveryNoteModal();
      })
      .catch((e) => {
        e.response.data.code === operationsErrors.FiscalYear.YEAR_IS_LOCKED
          ? appToast.error(t(operationsErrors.FiscalYear.YEAR_IS_LOCKED))
          : appToast.error(t('Something went wrong, please try again.'));
        setArchiveDeliveryNoteStatus(ArchiveDeliveryNoteStatus.ERROR);
      });
  };

  const handleApplyStatusFilter = (status: StatusEnum) => {
    setStatusFilter(status);
    setFilters({ ...filters, status: status === StatusEnum.ALL ? '' : status });
  };

  const handleDeleteDeliveryNoteModal = () =>
    setIsDeleteDeliveryNoteModal(!isDeleteDeliveryNoteModal);

  const handleArchiveDeliveryNoteModal = () =>
    setIsArchiveDeliveryNoteModal(!isArchiveDeliveryNoteModal);

  const transformIntoBill = (document: Document) => {
    document.withHoldingTaxRate = 0;
    document.withHoldingTax = 0;
    if (document.status !== StatusEnum.ARCHIVED) {
      setArchiveDeliveryNoteStatus(ArchiveDeliveryNoteStatus.IN_PROGRESS);
    }
    BillingService.createNewDocument(document, 'invoice')
      .then(() => {
        appToast.success(t('The delivery note has been transformed into bill'));

        //archive delivery note
        if (document.id && document.status !== StatusEnum.ARCHIVED) {
          BillingService.archiveDeliveryNote(document.id)
            .then(() => {
              appToast.success(t('DELIVERY_NOTE_ARCHIVED'));
              getDeliveryNotes();
              setArchiveDeliveryNoteStatus(ArchiveDeliveryNoteStatus.SUCCESS);
            })
            .catch((e) => {
              e.response.data.code ===
              operationsErrors.FiscalYear.YEAR_IS_LOCKED
                ? appToast.error(t(operationsErrors.FiscalYear.YEAR_IS_LOCKED))
                : appToast.error(t('SOMETHING_WENT_WRONG'));
              setArchiveDeliveryNoteStatus(ArchiveDeliveryNoteStatus.ERROR);
            });
        }
        getDeliveryNotes();
      })
      .catch(() => {
        appToast.error(t('Something went wrong, please try again.'));
      });
  };
  const duplicate = (document: Document) => {
    BillingService.createNewDocument(document, 'delivery-note')
      .then(() => {
        appToast.success(t('The delivery note has been duplicated'));
        getDeliveryNotes();
      })
      .catch(() => {
        appToast.error(t('Something went wrong, please try again.'));
      });
  };

  const menuItems = [
    {
      type: 'item',
      label: t('Review'),
      icon: <Receipt fontSize="inherit" htmlColor="#797e8a" />,
      onClick: (el: Document) =>
        navigate(`${el.id}/review`, {
          state: { documentType: 'delivery-note' },
        }),
    },
    { type: 'divider' },
    {
      type: 'item',
      label: t('Edit'),
      icon: <Edit fontSize="inherit" htmlColor="#797e8a" />,
      onClick: (el: Document) => {
        navigate(`update/template/${el?.id}`, {
          state: { documentType: 'delivery-note' },
        });
        update();
      },
    },

    { type: 'divider' },
    {
      type: 'item',
      label: t('Delete'),
      icon: <Delete fontSize="inherit" htmlColor="#d9534f" />,
      onClick: (el: Document) => {
        setSelectedId(el.id ?? '');
        handleDeleteDeliveryNoteModal();
      },
      disabled: fiscalYearUsed && fiscalYearUsed.locked,
    },
    { type: 'divider' },
    {
      type: 'item',
      label: t('Archive'),
      icon: <Delete fontSize="inherit" htmlColor="#d9534f" />,
      onClick: (el: Document) => {
        setSelectedId(el.id ?? '');
        handleArchiveDeliveryNoteModal();
      },
      isArchive: true,
    },
    { type: 'divider' },
    {
      type: 'item',
      label: t('transform into bill'),
      icon: <Delete fontSize="inherit" htmlColor="#d9534f" />,
      onClick: (el: Document) => {
        transformIntoBill(el);
      },
    },
    { type: 'divider' },
    {
      type: 'item',
      label: t('Duplicate'),
      icon: <Delete fontSize="inherit" htmlColor="#d9534f" />,
      onClick: (el: Document) => {
        duplicate(el);
      },
    },
  ];

  return (
    <section className="flex flex-col w-full gap-4 p-6 h-fit">
      <Tabs selected="Delivery Notes" />

      <ConfirmationModal
        isOpen={isDeleteDeliveryNoteModal}
        action={deleteDeliveryNote}
        onClose={handleDeleteDeliveryNoteModal}
        actionName={t('Delete')}
        sensitivity="Destructive"
        title={t('DELETE_DELIVERY_NOTE')}
        message={t('YOU_ARE_ABOUT_TO_DELETE_THIS_DELIVERY_NOTE')}
      />
      <ConfirmationModal
        isOpen={isArchiveDeliveryNoteModal}
        action={archiveDeliveryNote}
        onClose={handleArchiveDeliveryNoteModal}
        actionName={t('Archive')}
        sensitivity="Primary"
        title={t('Archive delivery note')}
        message={t(
          'you are about to archive this delivery note, are you sure?'
        )}
      />
      <div className="flex justify-end">
        <Button
          size="small"
          label={t('Create new delivery note')}
          icon={<Add htmlColor="white" />}
          iconPosition="left"
          onClick={() => {
            navigate('create/template', {
              state: { documentLatestNote, documentType: 'delivery-note' },
            });
          }}
          disabled={fiscalYearUsed && fiscalYearUsed.locked}
        />
      </div>
      <div className="flex flex-wrap items-center justify-between w-full">
        <DropdownMenu>
          <DropdownMenuTrigger>
            <ButtonComponent
              variant="outline"
              className="flex gap-2 items-center h-8 text-xs text-grayscale-text-caption"
            >
              Status:{' '}
              {statusFilter === StatusEnum.ALL ? (
                <span className="font-semibold text-grayscale-text-body">
                  {t('All')}
                </span>
              ) : statusFilter === StatusEnum.INIT ? (
                <span className="font-semibold text-warning-default">
                  {t('INIT')}
                </span>
              ) : statusFilter === StatusEnum.ARCHIVED ? (
                <span className="font-semibold text-negative-default">
                  {t('Archived')}
                </span>
              ) : null}
              <ChevronDown width={16} height={16} strokeColor="#5C6B7A" />
            </ButtonComponent>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="left-0">
            <DropdownMenuRadioGroup
              value={position}
              onValueChange={setPosition}
            >
              <DropdownMenuRadioItem
                value="top"
                onClick={() => {
                  handleApplyStatusFilter(StatusEnum.ALL);
                }}
                className="p-2 text-xs"
              >
                {t('All')}
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem
                value="bottom"
                onClick={() => handleApplyStatusFilter(StatusEnum.INIT)}
                className="flex gap-2 items-center p-2 text-xs"
              >
                <span className="h-2 w-2 bg-warning-default rounded-full"></span>
                {t('INIT')}
              </DropdownMenuRadioItem>
              <DropdownMenuRadioItem
                value="right"
                onClick={() => handleApplyStatusFilter(StatusEnum.ARCHIVED)}
                className="flex gap-2 items-center p-2 text-xs"
              >
                <span className="h-2 w-2 bg-negative-default rounded-full"></span>
                {t('Archived')}
              </DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-3xl bg-first">
        {!deliveryNotes?.length ? (
          <div className="flex flex-col items-center gap-5 p-3 mt-20 text-center text-gray-500 bg-white rounded-3xl">
            <img className="h-52" src={empty} alt="empty" />
            <h2 className="font-bold">{t('There is no delivery note')}</h2>

            <p className="max-w-[450px] text-sm ">
              {t(
                'You can create a new delivery note by clicking on the button above'
              )}
            </p>
          </div>
        ) : (
          <div className="overflow-x-auto p-[1px]">
            <table className="relative w-full overflow-x-auto text-left text-gray-700 border-b rounded-lg outline outline-1 outline-grayscale-light">
              {deliveryNotesStatus === DeliveryNotesStatus.IN_PROGRESS && (
                <div className="absolute z-10 flex items-center justify-center w-full h-full bg-white opacity-50 cursor-no-drop">
                  <LoadingSpinner
                    width="35"
                    strokeWidth="4"
                    strokeColor="#5d5ff8"
                  />
                </div>
              )}

              <thead className="text-gray-700 border-b uppercase">
                <tr>
                  <th
                    scope="col"
                    className="w-52 px-6 py-3 !text-xs !font-normal text-grayscale-text-caption"
                  >
                    {t('Date')}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 !text-xs !font-normal text-grayscale-text-caption w-4"
                  >
                    {t('Status')}
                  </th>
                  <th
                    scope="col"
                    className="w-32 px-6 py-3 !text-xs !font-normal text-grayscale-text-caption"
                  >
                    {t('Number')}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 !text-xs !font-normal text-grayscale-text-caption"
                  >
                    {t('Client')}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 !text-xs !font-normal text-grayscale-text-caption text-end"
                  >
                    {t('Amount')}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 !text-xs !font-normal text-grayscale-text-caption"
                  ></th>
                </tr>
              </thead>

              <tbody>
                {deliveryNotes.length ? (
                  deliveryNotes.map((el, idx) => (
                    <tr
                      onClick={(e) => {
                        e.cancelable = true;
                        e.stopPropagation();
                        navigate(`${el.id}/review`, {
                          state: { documentType: 'delivery-note' },
                        });
                      }}
                      key={el.id}
                      className={`border-bottom font-bold px-3 cursor-pointer text-xs ${
                        idx % 2 ? 'bg-white' : 'bg-slate-50'
                      }`}
                    >
                      <th
                        scope="row"
                        className="px-6 py-1 text-xs font-bold truncate text-grayscale-text-bold"
                      >
                        {moment(el.date).format('LL')}
                      </th>
                      <td className="px-6 py-1 truncate color-black">
                        <Badge
                          label={t(el?.status ?? '')}
                          sensitivity={
                            el.status === 'INIT' ? 'warning' : 'negative'
                          }
                        />
                      </td>
                      <th
                        scope="row"
                        className="px-6 py-4 text-xs font-bold truncate text-grayscale-text-bold"
                      >
                        {el.documentNumber || '-'}
                      </th>

                      <td className="px-6 py-1 text-xs font-bold truncate text-grayscale-text-bold">
                        {el?.clientName}
                      </td>

                      <td className="px-6 py-1 truncate color-black text-end">
                        <div className="truncate">
                          <p className="text-xs font-bold text-grayscale-text-bold">
                            {formatBalance(el?.totalTTC, el?.currency)}
                          </p>
                          <p className="text-[10px] font-normal text-grayscale-text-caption">
                            {t('VAT')}:{' '}
                            {formatBalance(el?.totalVAT, el?.currency)}
                          </p>
                        </div>
                      </td>

                      <td className="px-6 py-1 color-black truncate w-[10px]">
                        <div className="flex justify-center gap-2 text-gray-700">
                          <div className="flex items-center justify-center gap-2 text-base text-gray-700">
                            <Tooltip title={t('Edit')} placement="top">
                              <button
                                key={idx}
                                onClick={(e) => {
                                  e.cancelable = true;
                                  e.stopPropagation();
                                  navigate(`update/template/${el?.id}`, {
                                    state: { documentType: 'delivery-note' },
                                  });
                                }}
                                className="w-9 h-9 p-2 rounded-xl flex justify-center items-center disabled:opacity-50 hover:bg-primary-subtle"
                              >
                                <EditLine width={16} strokeColor="#0756f2" />
                              </button>
                            </Tooltip>
                          </div>
                          <button
                            onClick={(e) => {
                              e.cancelable = true;
                              e.stopPropagation();
                              handleToggleMenu(e, el.id);
                              setSelectedId(el?.id ?? '');
                            }}
                            className="w-9 h-9 p-2 rounded-xl flex justify-center items-center disabled:opacity-50 hover:bg-grayscale-subtle"
                          >
                            <MoreHoriz htmlColor="#656469" />
                          </button>

                          {isMenuOpen && selectedId === el.id && (
                            <div
                              className="fixed flex flex-col min-w-[100px] bg-first rounded-xl drop-shadow-2xl right-5 overflow-hidden dropdown-animation"
                              style={{
                                zIndex: 9999,
                                top: dropdownPosition.top,
                              }}
                              ref={menuRef}
                            >
                              {menuItems.map((item, idx) =>
                                item.type === 'divider' ? (
                                  <hr key={idx} />
                                ) : (
                                  <button
                                    disabled={
                                      deleteDeliveryNoteStatus ===
                                        DeleteDeliveryNoteStatus.IN_PROGRESS ||
                                      archiveDeliveryNoteStatus ===
                                        ArchiveDeliveryNoteStatus.IN_PROGRESS ||
                                      item?.disabled ||
                                      (el.status === StatusEnum.ARCHIVED &&
                                        item?.isArchive)
                                    }
                                    className={`flex cursor-pointer items-center text-[${
                                      item.label === t('Delete')
                                        ? '#d9534f'
                                        : '#797e8a'
                                    }] gap-2 hover:bg-[#eff3fd] px-3 py-2 duration-300 whitespace-nowrap disabled:opacity-50 disabled:cursor-auto`}
                                    key={idx}
                                    onClick={(e) => {
                                      e.cancelable = true;
                                      e.stopPropagation();
                                      item.onClick && item.onClick(el);
                                    }}
                                  >
                                    <p>{item.label}</p>
                                  </button>
                                )
                              )}
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="relative items-center justify-center w-full">
                    <td className="absolute flex flex-col items-center w-full h-full py-4">
                      <img className="max-w-[250px]" src={empty} alt="empty" />
                      <div className="flex flex-col w-full h-full gap-2 text-center">
                        <span className="color-gray text-[25px] font-bold">
                          {t('There is no delivery note')}
                        </span>

                        <p>
                          {t(
                            'You can add a new delivery note by clicking on the button above'
                          )}
                        </p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* PAGINATION */}
            <div className="flex justify-start w-full mt-6">
              <Pagination
                page={pageNumber}
                count={totalPages}
                onChange={setPageNumberFunction}
                variant="outlined"
                shape="rounded"
                color="primary"
                size="medium"
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

// const HEADERS_ITEMS = ['Number', 'Status', 'Date', 'Client', 'Amount', ''];

export default DeliveryNotes;
