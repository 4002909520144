import type { Bank } from '@/lib/forms';

import { settingsAPI } from './apis';
import type { Currency } from './models';

export default class SettingsService {
  static async getBanks(signal?: AbortSignal) {
    return settingsAPI.get<Bank[]>('v1/banks', {
      signal,
    });
  }

  static async getCurrencies(signal?: AbortSignal) {
    return settingsAPI.get<Currency[]>('v1/currencies', {
      signal,
    });
  }

  static async getCurrency(id: string, signal?: AbortSignal) {
    return settingsAPI.get<Currency[]>(`v1/currencies/${id}`, {
      signal,
    });
  }

  static async getCategories(signal?: AbortSignal) {
    return settingsAPI.get('v1/categories', {
      signal,
    });
  }

  static async getPlans(signal?: AbortSignal) {
    return settingsAPI.get('v1/plans/search/displayable', {
      signal,
    });
  }

  static async updatePlan(id: String, signal?: AbortSignal) {
    return settingsAPI.put(`/v1/plans/${id}`, {
      signal,
    });
  }
  static async exportData(signal?: AbortSignal, email?: string, name?: string) {
    return settingsAPI.get(`/v1/export?email=${email}&name=${name}`, {
      signal,
    });
  }

  static getAllPagesPlans(signal?: AbortSignal) {
    return settingsAPI.get(`/v1/pages-plans/search/displayable`, {
      signal,
    });
  }

  static getPagesPlanInfo(pagesPlanId: string, signal?: AbortSignal) {
    return settingsAPI.get(`/v1/pages-plans/${pagesPlanId}`, {
      signal,
    });
  }

  static getReferralConfig(signal?: AbortSignal) {
    return settingsAPI.get(`/v1/referral-config`, {
      signal,
    });
  }
}
