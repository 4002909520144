import { bankingAPI } from './apis';
import type {
  BankAccount,
  BankAccountPostRequest,
  BankAccountUpdateRequest,
} from './models';

export default class BankingService {
  static getBankAccounts(
    signal?: AbortSignal,
    fiscalYear: number | null = null
  ) {
    return bankingAPI.get(
      `v1/bank-accounts?${fiscalYear ? 'fiscalYear=' + fiscalYear : ''}`,
      {
        signal,
      }
    );
  }

  static postBankStatement(data: FormData, signal?: AbortSignal) {
    return bankingAPI.post('v1/bank-statements', data, {
      signal,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static postBankStatementFromDoc(
    year: number,
    month: number,
    bankAccountId: string,
    filePath: string,
    signal?: AbortSignal
  ) {
    const data = {
      year,
      month,
      bankAccountId,
      filePath,
    };
    return bankingAPI.post('v1/bank-statements/docs', data, {
      signal,
    });
  }

  static downloadBankStatement(id: string, signal?: AbortSignal) {
    return bankingAPI.get(`v1/bank-statements/${id}/download`, {
      signal,
      responseType: 'blob',
    });
  }

  static getStatements(signal?: AbortSignal) {
    return bankingAPI.get('v1/bank-statements', {
      signal,
    });
  }

  static postBankAccount(data: BankAccountPostRequest, signal?: AbortSignal) {
    return bankingAPI.post<BankAccount>('v1/bank-accounts', data, {
      signal,
    });
  }

  static updateBankAccount(
    id: string,
    data: BankAccountUpdateRequest,
    signal?: AbortSignal
  ) {
    return bankingAPI.put<BankAccountPostRequest>(
      `v1/bank-accounts/${id}`,
      data,
      {
        signal,
      }
    );
  }

  static deleteBankAccount(id: string, signal?: AbortSignal) {
    return bankingAPI.delete<BankAccountPostRequest>(`v1/bank-accounts/${id}`, {
      signal,
    });
  }

  static deleteBankStatement(id: string, signal?: AbortSignal) {
    return bankingAPI.delete(`v1/bank-statements/${id}`, {
      signal,
    });
  }
}
